$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.testimony-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .testimony-hero-section {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        padding: 2rem;
        background-color: #1f56c321;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: #000000;
                font-size: 3.5rem;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #666;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 2rem;
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }
   
    .all-testimony{

    }
    .subHeading {
        color: #000000;
        font-weight: 700;
        font-size: 35px;
    }
    .contentHeading {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 30px;
    }
    .aboutContent {
        text-align: justify;
        color: #7a7a7a;
        font-weight: 500;
        font-size: 20px;
    }
    .testimonyContainer{
        border: 1px solid #3D3D3D;
        border-radius: 20px;
        padding: 2rem;
        .testimonyHeading{
            font-size: 22px ;
            color: #2E2E2E;
            font-weight: 500;
            
            @media screen and (max-width:728px) {
                font-size: 18px ;
                
            }
            @media screen and (max-width:500px) {
                font-size: 16px ;
                
            }
        }
        .testimonyAuthor p{
            font-size: 20px ;
            color: #7A7A7A;
            font-weight: 500;
            @media screen and (max-width:728px) {
                font-size: 16px ;
                
            }
            @media screen and (max-width:500px) {
                font-size: 14px ;
                
            }
        }
    }
    
}
