.editResumeBg {
      background-color: rgba(224, 220, 243, 0.212) ;

}
.wholeProfileWrapper {
    margin: auto;
    @media screen and (max-width: 768px) {
        padding: 10px;
    }
}
.paddingWrapper {
    padding-top: 7rem;
    @media screen and (max-width: 768px) {
        padding-top: 3rem;
    }
}
@media screen and (max-width: 700px) {
    input::placeholder {
        font-weight: normal;
        font-size: 13px;
        color: #8b8b8b;
    }
}
.btn-info01 {
    border-radius: 8px;
    height: 40px;
    font-size: 13px;
    background-color: #4ea6cf;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: #4092b8;
        color: white;
    }
}

.text-right {
    //style={{ display: "flex", justifyContent: "flex-end" }}>
    display: flex;
    justify-content: flex-end;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.layoutDesign {
    box-shadow: 0px 0px 15px 0px #00000025;
    border-radius: 7px;
    margin-top: 40px;
    // min-height: 130px;
    // padding: 3rem 3rem;
    @media screen and (max-width: 700px) {
        margin-top: 20px;
    }
    @media screen and (max-width: 500px) {
        padding: 2rem 1rem;
    }
}
#portfolioWrapper,
#workExperienceWrapper,
#identityWrapper,
#docsWrapper,
#educationWrapper,
#personalDetailsWrapper,
#skillsWrapper,
#addressWrapper {
    padding: 3rem 5rem;
    background-color: white;
    @media screen and (max-width: 700px) {
        padding: 2rem 2rem;
    }
}
#educationWrapper{
    .updatebid{
       @media screen and (max-width: 500px) {
         width: 110px;
       }
    }
    .updatebidButton{
        @media screen and (max-width: 500px) {
            font-size: 12px;
        }
    }
    
}
.leftWrapper {
    background-color: white;
    .personalHeading {
        font-size: 17px;
        color: #666666;
        font-weight: 500;
        margin: 0px;
    }
    .personalContent {
        font-size: 16px;
        color: #333333;
        font-weight: 400;
    }
    @media screen and (max-width: 768px) {
        .personalHeading {
            font-size: 16px;
        }
        .personalContent {
            font-size: 15px;
        }
    }
}
.dynamicPadding {
    padding-right: 27px;
    @media screen and (max-width: 1100px) {
        padding-right: 12px;
    }
    @media screen and (max-width: 900px) {
        padding-right: 0px;
    }
    @media screen and (max-width: 850px) {
        padding-right: 0px;
    }
    @media screen and (max-width: 768px) {
        padding-right: 44px;
    }
    @media screen and (max-width: 700px) {
        padding-right: 30px;
    }
    @media screen and (max-width: 550px) {
        padding-right: 12px;
    }
    @media screen and (max-width: 450px) {
        padding-right: 5px;
    }
}
.skills-card {
    border: none;
    box-shadow: none;
    .skills-title {
        font-size: 20px;
        color: #404040;
        font-weight: 500;
        margin-bottom: 1.5rem;
    }

    .skill-item {
        padding: 0.5rem;
        border-radius: 6px;
    }

    .skill-name {
        font-size: 15px;
        font-weight: 600;
        color: #404040;
        margin-bottom: 4px;
    }

    .skill-level {
        font-size: 15px;
        color: #6e6e6e;
        margin-bottom: 0;
        margin-top: -4px;
    }
    .edit-btn {
        color: #2092c7;
        font-size: 20px;
        padding: 4px;
    }

    .delete-btn {
        color: #646464;
        font-size: 18px;
        padding: 4px;
    }
}
.address-section {
    border: none;
    box-shadow: none;
    .address-title {
        font-size: 2rem;
        font-weight: 500;
        color: #000;
    }

    .edit-btn {
        color: #2092c7;
        font-size: 1.5rem;
        transition: transform 0.2s ease;
        display: flex;
        align-items: center;

        &:hover {
            transform: scale(1.1);
            color: #2092c7;
        }
    }

    .address-field {
        padding: 0px;

        .field-label {
            font-size: 18px;
            color: #666666;
            font-weight: 400;
            margin-bottom: 0.5rem;
            @media screen and (max-width: 700px) {
                font-size: 16px;
            }
        }
        .field-value {
            font-size: 17px;
            color: #333;
            margin-bottom: 0;
            @media screen and (max-width: 700px) {
                font-size: 15px;
            }
        }
    }
    // .rightField {
    //     padding-left: 20px;
    //     @media screen and (max-width: 768px) {
    //         padding-left: 0px;
    //     }
    // }
}
.indentityWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    padding: 0rem 7rem;
    .identityphotoclass {
        height: 250px;
        max-height: 500px;
        object-fit: contain;
        cursor: pointer;
        width: 100%;
        @media screen and (max-width: 500px) {
            height: 200px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 0rem 3rem;
    }
    @media screen and (max-width: 1000px) {
        padding: 0rem 1rem;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.shortcut-newappliedjobForPreview {
    padding: 4px 16px;
    outline: none;
    transition: all 0.5s;
    font-weight: 500;
    border: 1px solid #00a5ec;
    background-color: white;
    color: #00a5ec;
    font-size: 16px;
    margin-left: 11px;

    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    // padding-left: 35px;
    // padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
}

.editProfileButton {
    background-color: #2d79bf;
    color: white;
    border: none;
    border-radius: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 500;
    margin-right: 15px;
    margin-bottom: 12px;
    align-content: center;
}
.personalDetailsWrapper {
    display: flex;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}
.leftWrapper {
    flex: 1;
    justify-content: stretch;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    padding: 5rem 3rem;
    @media screen and (max-width: 800px) {
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 700px) {
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 500px) {
        padding: 2rem 1rem;
    }
}
.rightWrapper {
    flex: 2;
    flex-direction: column;
    justify-content: stretch;
    margin-left: 10px;

    .rightTwo,
    .rightOne {
        padding: 3rem 5rem;
        background-color: white;
        justify-content: stretch;

    }
    .video-introduction {
        $border-radius: 7px;
        $primary-color: #2092c7;
        $text-color: #666666;
        padding: 3rem 5rem;
        width: 100%;
        background-color: #ffffff;
        border-radius: $border-radius;
        // border: 1px solid #cccccc;
        // height: ;
        overflow: hidden;
        @media screen and (max-width: 968px) {
            height: auto;
        }
        &__title {
            font-size: 18px;
            // font-weight: 500;
            color: $text-color;
            margin-bottom: 1rem;
        }

        &__link-container {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            margin-bottom: 1.5rem;
        }

        &__link {
            font-size: 17px;
            text-decoration: none;
            color: #2d79bf;
        }

        &__copy-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: $primary-color;
            position: relative;
            padding: 0.25rem;

            &:hover {
                background-color: rgba($primary-color, 0.1);
                border-radius: 4px;
            }
        }

        &__tooltip {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            font-size: 0.875rem;
            white-space: nowrap;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border: 5px solid transparent;
                border-top-color: rgba(0, 0, 0, 0.8);
            }
        }

        &__wrapper {
            position: relative;
            width: 100%;
            padding-top: 56.25%; // 16:9 aspect ratio
            margin-top: 1rem;

            @media (max-width: 768px) {
                padding-top: 75%; // 4:3 aspect ratio for mobile
            }
        }

        &__iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @media (max-width: 968px) {
                width: 100%;
                height: 100%;
            }
        }
    }
    .videoChangeHeight{
        height: auto;
        @media screen and (max-width: 968px) {
          height: auto;
      }
    }
    .rightTwo {
        margin-top: 20px;
    }
    @media screen and (max-width: 800px) {
        margin-left: 0px;
        .rightTwo,
        .rightOne {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 700px) {
        .rightTwo,
        .rightOne {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 500px) {
        .rightTwo,
        .rightOne {
            padding: 2rem 2rem;
        }
    }
}
.profileHeading{
  font-size: 24px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
    
  }
}
.ResumeBackBtn {
    margin-top: 40px;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    color: #2092c7;
    font-size: 14px;
    cursor: pointer;
}

.section31 {
    border-radius: 6px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
}

.dropdown-divider1 {
    margin-left: 15px;
    margin-right: 15px;
    height: 0.05px;
}

.ModelInput {
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
}

.saveUpdate {
    background-color: #1e9cf0;
    color: white;
    border: none;
    border-radius: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 500;
    margin-right: 15px;
    margin-bottom: 12px;
}

.saveUpdate:hover {
    background-color: #2092c7;
}

.custom-scrollbarresume::-webkit-scrollbar {
    width: 8px;
}

// .custom-scrollbarresume::-webkit-scrollbar-thumb {
//   background-color: #888;
//   border-radius: 10px;
// }

// .custom-scrollbarresume::-webkit-scrollbar-thumb:hover {
//   background-color: #555;
// }

.portfolio-tabs {
    margin-top: 20px;
}

.tabs-portfolio .item-portfolio .link-portfolio {
    color: #6d6d6d;
    font-size: 16px;
    font-weight: 500;
}

.tabs-portfolio .item-portfolio .link-portfolioactive {
    color: #2092c7;
    font-weight: bold;
    border: none;
    border-bottom: 3px solid #2092c7;
}

.card {
    margin: 10px 0;
}
.card-body{
    height: 65px;
    // background-color: red;
    overflow: hidden;
}
.card-img-top {
    width: 100%;
    height: auto;
}

.no-drafts {
    text-align: center;
    padding: 50px;
}

.no-drafts img {
    width: 100px;
    height: auto;
}

.no-drafts p {
    margin-top: 20px;
    color: #6d6d6d;
    font-size: 16px;
}

.pagination {
    margin-top: 20px;
    justify-content: right;
}

.pagination .page-item .page-link {
    color: #2092c7;
    border: none;
}

.pagination-navigation {
    gap: 10px;
}
.flex-container-portfolio {
    display: flex;
    gap: 30px;
    flex-direction: row;
}
.left-section {
    width: 40%;
    // background-color: red;
}
.right-section {
    width: 60%;
    //background-color: green;
}
.uploaded-image {
    width: 100%;
    height: 450px;
    border-radius: 8px;
}
.footer-conatiner {
    margin-top: 15px;
    border-top: 0.5px solid rgb(221, 221, 221);
    display: flex;
    justify-content: flex-end;
    padding-top: 13px;
    padding-right: 15px;
}

.icon-container {
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
    opacity: 0;
    transform: translateX(-20px);
}

.icon-container.visible {
    opacity: 1;
    transform: translateX(0);
}

.toggle-cross {
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.toggle-cross.visible {
    transform: translateY(0);
    opacity: 1;
}

.toggle-cross.hidden {
    transform: translateY(-20px);
    opacity: 0;
}

.icon-container.visible .icon {
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.icon-container.visible .icon:nth-child(1) {
    transition-delay: 0.1s;
}

.icon-container.visible .icon:nth-child(2) {
    transition-delay: 0.2s;
}

.icon-container.visible .icon:nth-child(3) {
    transition-delay: 0.3s;
}

.icon-container.visible .icon:nth-child(4) {
    transition-delay: 0.4s;
}

.icon-container.visible .icon:nth-child(5) {
    transition-delay: 0.5s;
}

.icon-container.visible .icon:nth-child(6) {
    transition-delay: 0.6s;
}

.portfolio-icons {
    font-size: 40px;
    color: #2092c7;
    border-radius: 50%;
    border: 1px solid gray;
    padding: 6px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and color */
}

/* Hover state */
.portfolio-icons:hover {
    background-color: #2092c7; /* Background changes to #2092c7 */
    color: white; /* Text color changes to white */
}
.custom-backdrop {
    backdrop-filter: brightness(0.5);
}

.video-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    // width: calc(100% - 18px);
    // height: 100%;
    height: calc(100% - 10px);
    bottom: 10px;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #676767;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 8px; /* Match the video border radius */
}

.video-container:hover .video-overlay,
.video-container:active .video-overlay {
    opacity: 1;
}

.modal.fade.in {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.modal.fade.out {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-in.show {
    opacity: 1;
}
.portcard{
    box-shadow: 0px 0px 15px 0px #00000025;

}
.portcard:hover .change-thumbnail-button {
    display: block;
}
.portcard:hover .edit-project-draft {
    display: block;
}
.portcard:hover .delete-project-draft {
    display: block;
}

.edit-project-draft {
    border-radius: 50%;
    color: #2092c7;
    background-color: white !important;
    padding: 7px;
    font-size: 30px;
}
.delete-project-draft {
    border-radius: 50%;
    color: #2092c7;
    background-color: white !important;
    padding: 7px;
    font-size: 30px;
}

.change-thumbnail-button {
    padding: 7px;
    background-color: white !important;
    color: #2092c7;
    border: 2px solid #2092c7;
    border-radius: 10px;
    font-size: 15px;
}

.dim-image {
    filter: brightness(50%);
    transition: filter 0.3s ease;
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.project-title:hover {
    text-decoration: underline;
}
.portofolioOptionWrapper {
    position: absolute;
    top: 50px;
    right: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    @media screen and (max-width: 1100px) {
        right: 25%;
    }
    @media screen and (max-width: 968px) {
        right: 20%;
    }
    @media screen and (max-width: 900px) {
        right: 15%;
    }
    @media screen and (max-width: 768px) {
        right: 40%;
    }
    @media screen and (max-width: 600px) {
        right: 35%;
    }
    @media screen and (max-width: 450px) {
        right: 30%;
    }
    @media screen and (max-width: 400px) {
        right: 25%;
    }
}
.dropdown-menu10 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -20%);
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 3px;
    border-radius: 4px;

    min-width: 150px;
}

.dropdown-item10 {
    padding: 5px 0px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
}

.dropdown-item10:hover {
    background-color: #f0f0f0;
    color: #2092c7;
}

.modal-backdrop10 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.fade-in10 {
    opacity: 1;
}

.fade-out10 {
    opacity: 0;
}

.modal-dialog10 {
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
}

.slide-in10 {
    transform: translateY(0);
    opacity: 1;
}

.slide-out10 {
    transform: translateY(-100px);
    opacity: 0;
}

.dragableportfoliosize {
    width: 180px;
}

.width-settingofmodal {
    max-width: 80%;
}
.profileDiv {
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
    .profileTitle {
        font-size: 18px;
        color: black;
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }
}
.phototodisplayindesktop {
    object-fit: cover;
    width: 135px;
    height: 135px;
    border-radius: 100%;
    margin-right: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .profileEditIconWrapper {
        background-color: #2092c7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
        position: absolute;
        top: 70%;
        right: 0%;
        .profileEditIcon {
            font-size: 18px;
            cursor: pointer;
            margin-top: 5px;
            color: white;
        }
    }
    @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;

        .profileEditIconWrapper {
            background-color: #2092c7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: inline-block;
            position: absolute;
            top: 70%;
            right: 0%;
            .profileEditIcon {
                font-size: 16px;
                margin-top: 3px;
            }
        }
    }
}
.phototodisplayinmobile {
    width: 131px;
    height: 137px;
    object-fit: cover;
    border-radius: 50%;
    // box-shadow: 0 0 15px rgba(116, 204, 244, 0.5);
    // border: 3px solid rgba(116, 204, 244, 0.5);
}

.formgroupaddress {
    display: flex;
}

.formgroupaddress label {
    display: block;
    margin-bottom: 5px;
    gap: 10px;
}

.formgroupaddress input,
.formgroupaddress select {
    width: 100%;
}

.phonegroupaddress {
    display: flex;
}

.phonegroupaddress select {
    width: 25%;
    margin-right: 5px;
}

.phonegroupaddress input {
    width: 75%;
}
// .close-button:hover {
//   color: green;
// }
.close-btn {
    font-size: 30px;
    border-width: 0px;
    border-color: transparent;
    cursor: pointer;
}
.close-btn:hover {
    color: rgba(9, 125, 171, 0.998);
    //background-color: rgba(5, 149, 207, 0.998);
}
.preview-header {
    /* From https://css.glass */
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

// .identityphotoclass {
//   width: 450px;
// }
#portfoliomodal .portfolioDialogModal {
    min-width: 90% !important;
}

@media (max-width: 600px) {
    .phonegroupaddress {
        flex-direction: column;
    }

    .phonegroupaddress select,
    .phonegroupaddress input {
        width: 100%;
        margin-right: 0;
    }

    .formgroupaddress {
        display: flex;
        flex-direction: column;
    }

    .formgroupaddress2 {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1298px) {
    .ResumeBackBtn {
        margin-left: 40px;
    }
}

@media screen and (max-width: 700px) {
    #portfoliomodal .portfolioDialogModal {
        width: 98%;
    }
    .section31 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .ResumeBackBtn {
        margin-left: 20px;
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .flex-container-portfolio {
        flex-direction: column;
        .left-section {
            width: 100%;
        }
        .right-section {
            width: 100%;
        }
    }
}

@media screen and (max-width: 450px) {
    .pagination-navigation {
        gap: 0px;
    }

    .dragableportfoliosize {
        width: auto;
        font-size: 14px;
    }

    .width-settingofmodal {
        max-width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .mobile-flex-container {
        display: flex;
    }
    // .mobile-column-to-increase-width {
    //   // min-width: 310px;
    // }
}

.phototodisplayinmobile {
    display: flex;
}

.post1234forresume {
    border: 1px solid #cccccc;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    color: #4d4d4d;
    text-align: center;
    display: inline-block;
    background-color: #fafafa;
}

@media (min-width: 768px) {
    .phototodisplayindesktop {
        display: block;
    }

    .phototodisplayinmobile {
        display: none;
    }
}

@media (min-width: 1024px) {
    .porfoliomodalwidthset {
        max-width: 60%;
    }
}

.video-wrapperresumevideo iframe {
    width: 560px;
    height: 290px;
}

@media (max-width: 768px) {
    .video-wrapperresumevideo iframe {
        width: 450px;
        height: 230px;
    }
}

@media (max-width: 500px) {
    .video-wrapperresumevideo iframe {
        width: 260px;
        height: 215px;
    }
}
