.card-r {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 16px;
  margin-right: 9px;
  margin-left: 9px;
  height: 250px;
}
.card-r > .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: none;
  border-radius: 16px;
  padding: 20px;
  background-color: #ffffff;
  // width: 288px;
  height: 100%;
  min-height: 250px;
  text-decoration: none;
}

.card-r > .card-container:hover {
  text-decoration: none; /* Prevent text underlining on hover */
}

.card-container {
  font-weight: 500;
  text-decoration: none;
  // cursor: pointer;
  line-height: 1.14285714;
}

.actively-hiring-label {
  padding: 3px 7px;
  border: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  margin-bottom: 12px;
}
.actively-hiring-label span {
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 500;
  color: #484848;
}

.heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 6px;
  margin: 0 0 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.heading .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.heading .content h6 {
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}

.heading .content span {
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 500;
  color: #8a8a8a;
}

.heading img {
  width: 48px;
}

.card-r > .card-container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 12px;
  list-style-type: none;
  padding: 0;
  margin: 0 0;
}

.card-r > .card-container ul li {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  color: #484848;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 4px;
}
.card-r > .card-container ul li svg {
  color: #8a8a8a;
  font-size: 16px;
}

.footer {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer .job-label {
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 500;
  padding: 2px 6px;
  background-color: #eee;
  border-radius: 4px;
  color: #666;
}

.footer .view-details-cta {
  font-size: 14px;
  line-height: 1.14285714;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}
