* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.platform-charges-payment {
    background-color: #fff;
    padding: 60px 0;
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .title {
        font-size: 35px;
        font-weight: 700;
        color: #1f57c3;
        margin-bottom: 30px;
    }

    .description {
        font-size: 16px;
        color: #7a7a7a;
        font-weight: 500;
        margin-bottom: 40px;
    }

    .section-title {
        font-size: 30px;
        font-weight: 600;
        color: #3d3d3d;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        color: #7a7a7a;
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        margin-top: 10rem;
    }
    @media (max-width: 500px) {
        margin-top: 15rem;
    }

    .support {
        margin-top: 60px;
        background-color: #1f57c3;
        width: 100%;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 3rem;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .content {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
        .contentImg {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
        }
        .support-title {
            font-size: 25px;
            font-weight: 600;
            color: white;
            margin-bottom: 10px;
            
        }

        .support-text {
            font-size: 35px;
            color: white;
            margin-bottom: 20px;
        }

        .support-btn {
            background-color: #ffffff;
            color: #333333;
            border: none;
            border-radius: 20px;
            padding: 8px 24px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        padding: 40px 0;

        .title {
            font-size: 25px;
            margin-bottom: 20px;
        }

        .description {
            font-size: 16px;
            margin-bottom: 30px;
        }

        .section-title {
            font-size: 21px;
        }
        
        p {
            font-size: 16px;
        }

        .support {
            margin-top: 40px;
            padding-top: 2rem;
            .support-title {
                font-size: 20px;
            }
            .content {
                flex: 1;
                width: 100%;
                justify-content: center;
                align-items: center;

            }
            .contentImg {
                width: 100%;
                flex: 1;
                justify-content: center;

            }
            .support-text {
                font-size: 30px;
            }

            .support-btn {
                font-size: 15px;
                padding: 10px 20px;
            }
        }
    }

    @media (max-width: 480px) {
        padding: 30px 0;

        .title {
            font-size: 20px;
            margin-bottom: 20px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 30px;
        }

        .section-title {
            font-size: 17px;
        }
        
        p {
            font-size: 14px;
        }

        .support {
            margin-top: 40px;
            padding-top: 2rem;
            padding: 2rem 1rem 0rem 1rem;
            .support-title {
                font-size: 18px;
            }
            .contentImg {
                img{
                    width: 50%;
                    height: auto;
                }

            }
            .support-text {
                font-size: 20px;
            }

            .support-btn {
                font-size: 14px;
                padding: 10px 20px;
            }
        }
    }
}
