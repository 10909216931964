
.filter-container {

    background-color: rgb(255, 255, 255);
    padding: 30px 0px;
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #484848;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        padding-top: .3rem;

        .filter-category {
            display: flex;
            flex-direction: column;

            .inner-filter-container{
                display: flex;
                align-items: center;
                width: 100%;

                .inner-filter-duration{
                    display:flex;
                    justify-content:center;
                    align-items:flex-start;
                    flex-direction: column;
                    margin-right: 2px;
                    width: 48% !important;
                }
                .inner-filter-durationType{
                    display:flex;
                    justify-content:center;
                    align-items:flex-start;
                    flex-direction: column;
                    margin-left: 2px;
                    width: 52% !important;
                }
                .inner-filter-minimum{
                    display:flex;
                    justify-content:center;
                    align-items:flex-start;
                    flex-direction: column;
                    width: 50%;
                    
                    input{
                        margin-right: 5px;
                    }
                }
                .inner-filter-maximum{
                    display:flex;
                    justify-content:center;
                    align-items:flex-start;
                    flex-direction: column;
                    margin: 0px 4px;
                    width: 50%;
                    input{
                        margin-left: 5px;
                    }
                }
                .inner-filter-category{
                    margin: 0px 2px ;
                    display: flex;
                    /* justify-content: flex-start; */
                    align-items: center;
                    width: 40%;

                    input{
                        width: max-content;
                        margin-right: 5px;
                    }
                    label{
                        margin-bottom: 0;
                    }
                }
                
            }
           
            
        }

        .filter-category> :first-child {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.571;
            color: #333;
            /* margin-bottom: 4px; */
        }

        .parent-dropdown2 {
            position: relative;
            padding: 2px 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border: 1px solid #eee;

        }

        .dates {
            /* width: fit-content; */
            background-color: #00a5ec;
            color: white;
            padding: 0px 10px;
            border-radius: 4px;
            display: flex;
           /* align-items: center;*/
            gap: 4px;
            cursor: pointer;
        }

        .dropdown {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 3px;
            background: white;
            height: fit-content;
            max-height: 200px;
            position: absolute;
            width: 100%;
            top: 44px;
            display: flex;
            flex-direction: column;
            z-index: 100;
            overflow-y: auto;
            float: left;
            text-align: left;
            color: #212529;
            font-weight: 400;
        }

        .dropdown div {
            padding: 4px 12px;
            font-weight: 400;
        }



        .dropdown>div:hover {
            background-color: #eafcff;
            color: #008bdc;
            cursor: pointer;
        }

        .parent-dropdown2:hover,
        .parent-dropdown:focus,
        .date-input:focus,
        .date-input:hover {

            border: 1px solid #00a5ec;

        }

        .filter-category input {
            width: 100%;
            outline: none;
            box-shadow: none;
            background-color: white;
            color: #484848;
            border: 1px solid #ddd;
            border-radius: 3px;
            padding: 8px 12px;

        }
        



        .filter-category input:hover,
        .filter-category input:focus {
            border: 1px solid #00a5ec;
        }
        .checkbox-container {
            .checkBoxInnerContainer{
                /* display: flex; */
                gap: 12px;
            }
        }
        .checkbox-container .category {
            display: flex;
            align-items: center;
            
            
            input {
                width: 15px;
                height: 15px;
                cursor: pointer;
            }

            div {
                font-size: 14px;
                color: #333;
                font-weight: 400;
            }
        }

        .instruction {
            color: #00a5ec;
            font-weight: 500;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

}

.filter-container:last-child {
    /* margin-top: 20px; */
    width: 100%;

    .body> :first-child {
        display: flex;
        align-items: center;
        height: 35px;
        width: 100%;
    }

    input {
        width: 100%;
        height: 100%;
        border: 1px solid #ddd;
        outline: none;
        padding: 6px 10px;
    }
    input::placeholder{
        font-weight: normal;
        font-size: 13px;
        color: #8b8b8b;
      }

    button {
        height: 100%;
        color: white;
        background-color: #00a5ec;
        border: 1px solid #00a5ec;
        padding: 0px 10px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        transition: all 0.3s;
    }

    button:hover {
        background-color: #007bff;
    }

}