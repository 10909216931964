.my-applicationsAdmin {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    padding: 6rem 1rem;
    min-height: 60vh;
    .mainWrappper {
        background-color: white;
        box-shadow: 0px 0px 15px 0px #00000025;
        border-radius: 7px !important;
        padding: 3rem;
        @media screen and (max-width:700px) {
            padding: 1rem 2rem;
        }
    }
    .tableWrapper {
        width: 100%;
        display: flex;
        justify-items: center;
        align-items: center;
    }
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 20px;
        color: #2b2b2b;
    }

    .table-box {
        max-width: 1200px;
        overflow: auto;
        background: #ffffff;
        border: 1px solid #e7e7e7 !important;
        box-shadow: none;
        border-radius: 6px;
        margin-top: -20px;

        table.applications {
            background: #ffffff;
            width: 100%;
            color: rgb(102, 102, 102) !important;

            .three-dots-menu {
                position: relative;
                margin-top: -18px;
            }

            .menu-icon {
                cursor: pointer;
            }

            .dot {
                border-radius: 50%;
                width: 3px;
                height: 3px;
                margin: 2px;
                background: #616161;
            }
            .menu {
                position: absolute;
                top: 24px;
                right: 0;
                background-color: #fff;
                border: 1px solid #ccc;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                padding: 10px;
                z-index: 1;
            }

            .menu ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            .menu li {
                margin: 5px 0;
                line-height: 30px;
                cursor: pointer;
            }

            .menu li:hover {
                color: black;
                background: #d3eaff;
            }

            tr {
                border-bottom: 1px solid rgb(245, 245, 245);

                &:hover {
                    background-color: white !important;
                }
                &:last-child {
                    border: none !important;
                }
                .smallTd {
                    @media screen and (max-width: 728px) {
                        min-width: 130px !important ;
                    }
                }
                .smallTdProfile {
                    @media screen and (max-width: 728px) {
                        min-width: 300px !important ;
                    }
                }
                .smallTdDate {
                    @media screen and (max-width: 728px) {
                        min-width: 100px !important ;
                    }
                }
            }

            td,
            th {
                border-top: none !important;
            }

            th {
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                font-size: 15px !important;
                letter-spacing: 0.9px;
                border-bottom: none;
                color: white !important;
                background: #4b92c8;
                text-align: start;
                // padding-left: 45px;
                text-align: center;
                text-transform: capitalize !important;

                .tableHead {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 150px !important;
                    margin: 0px 5px;
                }
                .tableHeadMoreWidth {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px !important;
                    margin: 0px 5px;
                }
            }

            td {
                padding: 3rem 1rem;
                font-size: 13px !important;

                &:last-child {
                    border-right: none;
                }
            }

            tr:last-child {
                td {
                    border: none !important;
                }
            }

            td {
                height: 60px;

                strong.status {
                    padding: 1rem 3rem;
                    // font-size: 1.3rem;
                    border-radius: 15px;
                }

                .viewed {
                    background: #d8ecff;
                    border-radius: 15px;
                    color: #4b92c8;
                    font-weight: 500;
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-size: 13px;
                }

                .view {
                    cursor: pointer;
                    color: #006bc2;
                }
            }
        }
    }
}
