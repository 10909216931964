.loading-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
  }

  .dot-animation::after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    animation: dots 1.5s infinite steps(4, end);
  }

  @keyframes dots {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }