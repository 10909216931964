@import './_variables';

body {
    background-color: white;
  &.no-footer {
    .app-footer {
      display: none;
    }
  }

  &.no-header-extended {
    #extended-header {
      display: none !important;
    }
  }

  &.no-footer-extended {
    #expanded-footer {
      display: none !important;
    }
  }

  &.footer-grey {
    .app-footer {
      background-color: rgba(35, 35, 35, 0.1);
    }
  }
}

.arrow-icon {
  display: inline-block;
  width: 8px;
  height: 6px;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  background-color: white;
}

.btn {
  text-align: center;
  padding: 0.8rem 1rem;
  text-decoration: none;
  border-radius: 2px;
  transition: 0.3s ease-out;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.btn-primary {
  border: 1px solid $PRIMARY_COLOR !important;
  background-color: #2092c7 !important;
  color: white !important;

  &:hover {
    background-color: $PRIMARY_COLOR_2 !important;
  }
}

.btn-danger {
  border: 1px solid $RED_COLOR !important;
  background-color: $RED_COLOR !important;
  color: white !important;

  &:hover {
    background-color: $RED_COLOR_2 !important;
  }
}

.btn-primary-outline {
  border: 1px solid $PRIMARY_COLOR !important;
  color: $PRIMARY_COLOR !important;

  &:hover {
    color: white !important;
    background-color: $PRIMARY_COLOR_2 !important;
  }
}

a {
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
}

a.btn {
  text-decoration: none;
}

.chip {
  border: 1px solid $PRIMARY_COLOR;
  padding: 0.8rem 1.2rem;

  &.rounded {
    border-radius: 15px;
  }
}

.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.1);
}
