.home-companies-box1 {
    padding: 2rem ;


    .title {
        margin: 2rem;
        text-align: center;
    }
    
    .companies {
        display: flex;
        flex-wrap: wrap;
        gap: 6rem;
        padding: 3rem;
        justify-content: center;

        
        img {
            height: auto;
            width: 100%;
        }
    }
}

@media screen and (min-width: 540px) {
    .home-companies-box1 {
        padding: 2rem ;
        
        .companies {
            padding: 6rem 10rem;

            img {
                height: 50px;
                width: auto;
            }
        }
    }
}