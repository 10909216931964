.join-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  height: 91.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(224, 220, 243, 0.212);

  @media (max-width: 500px) {
    height: 100vh;
    // margin-top: 5rem;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 40px;
    font-weight: 600;
  }

  .role-cards {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 32px;
    
    
    
    @media (max-width: 728px) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .role-card {
    width: 330px;
    padding: 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: white;
    box-shadow: 0px 0px 15px 0px #00000025;
    display: block; // Changed to block since it's now a label
    @media (max-width: 500px) {
      width: 100%;
     
    }
    &:hover {
      border-color: #1F57C3;
    }

    &.selected {
      border: 2px solid #1F57C3;
      background-color: #f8f9ff;
    }

    .card-content {
      display: flex;
      align-items: flex-start;
      text-align: left;
    }

    .radio-wrapper {
      margin-right: 16px;
      position: relative;
      padding-top: 4px;

      input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .custom-radio {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid #1F57C3;
        border-radius: 50%;
        
        &::after {
          content: "";
          position: absolute;
          display: none;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #1F57C3;
        }
      }

      input[type="radio"]:checked ~ .custom-radio::after {
        display: block;
      }
    }

    .card-details {
      flex: 1;
      width: 100%;
    }
    .card-details p{
      font-size: 30px;
      font-weight: 600;
      @media (max-width: 400px) {
        font-size: 25px;
       
      }
    }

    .icon {
      margin-bottom: 16px;
      
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }

    .role-title {
      font-size: 16px;
      font-weight: 500;
      // background-color: red;
      width: 100%;
      margin: 0;
      margin-bottom: 4px;
    }

    .role-subtitle {
      font-size: 18px;
      margin: 0;
      color: #333;
    }
  }

  .join-button {
    background-color: #1F57C3;
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    min-width: 200px;

    &:hover {
      background-color: #0047b3;
    }
  }
  .disable-button {
    background-color: #f8f9ff;
    color: #1F57C3;
    border: none;
    padding: 12px 32px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: not-allowed;
    border: 1px solid #1F57C3;
    transition: background-color 0.2s ease;
    min-width: 200px;
  }

  .login-section {
    margin-top: 24px;
    
    p {
      color: #4a4a4a;
      font-size: 16px;
    }

    a {
      color: #1F57C3;
      text-decoration: underline;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}