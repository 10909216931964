/* src/components/JobCategorySelect.css */

.job-select-container {
  /* min-width: 50%; */
  width: 100%;
  /* margin: 0 auto; */
}

.react-select__control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  font-size: 16px;
  min-height: 30px;
}

.react-select__menu {
  border-radius: 4px;
}

.react-select__option {
  display: flex;
  align-items: center;
}
.react-select__placeholder{
  font-weight: 400;
  font-size: 13px;
  color: #a9a9a9;
}
.react-select__option--is-focused {
  background-color: #f0f0f0;
}

.react-select__option--is-selected {
  background-color: #e0e0e0;
}

.react-select__single-value {
  display: flex;
  align-items: center;
}
