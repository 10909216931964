$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-black: #000000;
$text-gray: #7a7a7a;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.enterprise-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .enterprise-hero-section {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        padding-top: 3rem;
        // padding: 2rem;
        // background-color: #1f56c321;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: $primary-blue;
                font-size: 3.5rem;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: $text-gray;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.8rem;
                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }

    
    // How it works section
    .how-it-works {
        
        h2 {
            font-size: 40px;
            margin-bottom: 2rem;
            font-weight: 600;
            color: $text-black;
            @media (max-width: 1024px) {
                font-size: 2.5rem;
            }
            @media (max-width: 728px) {
                font-size: 2rem;
            }
        }
        margin-bottom: 4rem;

        &__content {
            display: flex;
            gap: 3rem;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        &__image {
            flex: .8;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }

        &__steps {
            flex: 1.2;
        }

        .steps {
            .step {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;
                justify-content: flex-start;
                &__number {
                    background: $text-black;
                    color: white;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1rem;
                    flex-shrink: 0;
                    margin-top: .4rem;
                    @media (max-width: 728px) {
                        margin-top: 0rem;
                        
                    }
                }

                p {
                    margin: 0;
                    font-size: 2.2rem;
                    font-weight: 500;
                    @media (max-width: 1024px) {
                        font-size: 1.8rem;
                    }
                    @media (max-width: 728px) {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    // Features section
    .features {
        background-color: #00a884;
        color: white;
        padding: 3rem;
        border-radius: 12px;
        margin-bottom: 4rem;

        h2 {
            // text-align: center;
            color: white;
            font-size: 3rem;
            margin-bottom: 3rem;
            @media (max-width: 728px) {
                font-size: 2rem;
            }
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 2rem;
        }

        .feature {
            &__icon {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
            }

            h3 {
                font-size: 2rem;
                margin-bottom: 1rem;
                font-weight: 400;
                @media (max-width: 728px) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-size: 1.6rem;
                line-height: 1.5;
                @media (max-width: 728px) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    // Solutions section
    .solutions {
        h2{
            font-size: 40px;
            margin-bottom: 2rem;
            @media (max-width: 1024px) {
                font-size: 30px; 
            }
            @media (max-width: 768px) {
                font-size: 2.5rem; 
            }
            @media (max-width: 500px) {
                font-size: 2.0rem; 
            }
        }
        &__content {
            background: url(../../../assets/images/enterprise_3.png);
            display: flex;
            gap: 3rem;
            min-height: 50vh;
            overflow: hidden;
            border-radius: 1rem;
            align-items: center;
            padding-left: 4rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media (max-width: 800px) {
                height: 100%;
                padding-left: 0rem;
                flex-direction: column;
                background: none;
            }

        }
        
        &__info {
            width: 40%;
            @media (max-width: 1100px) {
                width: 50%;
            }
            @media (max-width: 800px) {
                width: 100%;
                height: 100%;
            }
        }

        &__card {
            background-color: #c25934;
            color: white;
            padding: 2rem;
            border-radius: 12px;
            margin: 1rem 0rem;


            h3 {
                font-size: 2rem;
                margin-bottom: 1rem;
                margin-top: 1.5rem;

                &:first-child {
                    margin-top: 0;
                }
                @media (max-width: 768px) {
                    font-size: 1.8rem; 
                }
            }

            p {
                font-size: 18px;
                line-height: 1.5;
                font-weight: 400;
                opacity: 0.9;
                margin-bottom: 1.5rem;
                @media (max-width: 768px) {
                    font-size: 1.5rem; 
                }
            }
        }

        &__image {
            flex: 1;

            img {
                width: 100%;
                height: auto;
                border-radius: 8px;
            }
        }
    }

    .collaborate {
        background-color: #E9EFF9;
        color: $text-black;
        padding: 3rem;
        border-radius: 12px;
        margin-bottom: 1rem;
        margin-top: 3rem;

        h2 {
            // text-align: center;
            color: $text-black;
            font-size: 3rem;
            margin-bottom: 3rem;
            @media (max-width: 728px) {
                font-size: 3rem;
            }
            @media (max-width: 500px) {
                font-size: 2rem;
            }
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 2rem;
        }

        .feature {
            &__icon {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
            }

            h3 {
                font-size: 2rem;
                margin-bottom: 1rem;
                @media (max-width: 728px) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-size: 1.6rem;
                line-height: 1.5;
                @media (max-width: 728px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
    
}
