@import "../../../_variables";

.login-component {
    padding: 3rem 1rem;
    display: flex;

    background-color: rgba(224, 220, 243, 0.212);

    justify-content: center;
    height: 91.5vh;
    .form-box {
        padding: 2rem 0;
        // width: 100%;

        .form {
            background-color: white;
            padding: 2rem 3rem;
            padding-bottom: 4rem;
            box-shadow: 0px 0px 15px 0px #00000025;

            border-radius: 10px;
            // border: 1px solid #00000040;
            margin-top: 2rem;
            //    width: 400px;
            //    display: flex;
            //    justify-content: center;
            //    align-items: center;
            //    flex-direction: column;
            max-width: 400px;
            .title {
                font-size: 2.5rem;
                text-align: center;
            }
            .icons {
                width: 20px;
                height: 20px;
                object-fit: cover;
            }
            .login-type {
                display: flex;
                gap: 1rem;
                padding-bottom: 2rem;
                justify-content: space-between;

                strong,
                a,
                button {
                    transition: 0.3s;
                    padding: 0.8rem;
                    width: 100%;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #1f57c3;
                        color: white;
                        border: white 0.1px solid;
                        border-radius: 3px;
                    }

                    &.active {
                        border-bottom: 5px solid $PRIMARY_COLOR;
                    }
                }
            }

            .btn {
                //     color: black;
                //     margin: 1.5rem 0;
                //     text-align: center;
                //     display: block;
                //     font-weight: 500;
                width: 100%;
                margin-top: 3rem;
                background: #1f57c3;
                color: white;

                &:hover {
                    background-color: #1f57c3; // Change to your desired hover background color
                    color: white; // Change to your desired hover text color
                }
            }

            .title {
                text-align: center;
            }

            .label {
                font-weight: 500;
                padding: 1rem 0;
                padding-bottom: 2rem;

                a {
                    color: $PRIMARY_COLOR;
                }
            }

            .name-box {
                display: flex;
                gap: 2rem;
            }

            button {
                border-radius: 5px;
            }

            * {
                font-size: 1.4rem;
            }

            .input-box {
                padding: 1rem 0;
                position: relative;
                label {
                    display: block;
                    padding-bottom: 0.8rem;
                    font-weight: 500;
                }

                .forgot {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 1.2rem;
                    color: $PRIMARY_COLOR;
                    font-size: 1.3rem;
                    font-weight: 500;
                }

                input {
                    background-color: white;
                    padding: 0.8rem;
                    width: 100%;
                    border: 1px solid #d9d9d9;
                    border-radius: 5px;
                    outline: none;
                    transition: 0.3s;

                    &:focus {
                        border: 1px solid $PRIMARY_COLOR;
                    }
                }
            }
        }
    }
}
.Toastify__close-button {
    position: absolute;
    top: 12px;
    right: 8px;
}

@media screen and (min-width: 420px) {
    .login-component {
        padding: 8rem 3rem;

        .form-box {
            min-width: 350px;

            .form {
                padding: 2rem 3rem;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .login-component {
        .form-box {
            width: 300px;
        }
    }
}
