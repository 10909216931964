
// Variables
$primary-blue: #1F57C3;
$secondary-blue: #1d4ed8;
$text-dark: #111827;
$text-gray: #7a7a7a;
$background-light: #ffffff;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin content-width {
  max-width: 1200px;
  margin: 0 auto;
  // padding: 0 2rem;
  // background-color: #6b7280;
}


*{
  font-family: "Poppins", BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}
a{
  color: #1F57C3;
  text-decoration: none;
}
.icons{
  width: 35px;
  height: 35px;
  margin-right: 10px;
  object-fit: cover;
}
.mainWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
// Hero Section
.hero-section {
  background-color: $background-light;
  padding: 4rem 2rem;
  overflow: hidden;
  .content-wrapper {
    @include content-width;
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    align-items: flex-start;
    @media (min-width: 1024px) {
      
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
    }
    @media (max-width: 1024px) {
      .content-wrapper {
        padding: 0 1rem;
      }
      .hero-content{
        grid-row: 2;
      }
      .hero-illustration{
        grid-row: 1;
      }
    }
  }

  .hero-content {
    // background-color: rebeccapurple;
    
    h1 {
      font-weight: 700;
      color: $text-dark;
      margin-bottom: 2rem;
      font-size: 5rem;
      @media (max-width: 768px) {
          font-size: 2.5rem;
      }
    }

    .subtitle {
      font-size: 2.5rem;
      color: $text-gray;
      margin-bottom: 3rem;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    }

    .get-started-btn {
      background-color: $primary-blue;
      color: white;
      padding: 0.75rem 2rem;
      border-radius: 0.5rem;
      font-weight: 600;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: $secondary-blue;
      }
    }
    
  }

  .hero-illustration {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 80%;
      height: auto;
      object-fit: cover;
    }
    @media (max-width: 1024px) {
      img {
        width: 30%;
      }
    }
    @media (max-width: 728px) {
      img {
        width: 50%;
      }
    }
  }
}

// Performance Section
.performance-section {
  padding: 4rem 2rem;
  width: 100%;
  .content-wrapper {
    @include content-width;
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      // width: 100%;
    }
  }

  .image-container {
    border-radius: 1rem;
    overflow: hidden;
    @media (max-width:1024px) {
      display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 728px) {
        width: 80%;
      }
    }
  }

  .content {
    h2 {
      font-size: 3rem;
      font-weight: 700;
      color: $text-dark;
      margin-bottom: 2rem;
      @media (max-width: 1024px) {
        width: 100%;
      }
      @media (max-width: 728px) {
        font-size: 2rem;
        width: 100%;
      }
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 2rem;
      @media (max-width: 728px) {
        img{
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .feature {
        display: flex;
        gap: 1rem;

        .feature-icon {
          width: 1.5rem;
          height: 1.5rem;
          color: $primary-blue;
          flex-shrink: 0;
        }

        .feature-text {
          h3 {
            font-size: 2rem;
            font-weight: 600;
            color: $text-dark;
            margin-bottom: 0.5rem;
            @media (max-width: 728px) {
              font-size: 1.5rem;
            }
          }

          p {
            color: #000000;
            line-height: 1.5;
          }
        }
      }
    }

    .cta-buttons {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      button {
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s;
      }

      .sign-up-btn {
        background-color: $primary-blue;
        color: white;
        border: none;

        &:hover {
          background-color: $secondary-blue;
        }
      }

      .learn-more-btn {
        background-color: white;
        color: $primary-blue;
        border: 1px solid $primary-blue;

        &:hover {
          background-color: $background-light;
        }
      }
    }
  }
}

// EnterprisePackage.scss



.enterprise-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #16A085;
  border-radius: 24px;
  overflow: hidden;
  @include content-width;
  margin: 0rem 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .content-section {
    // @include content-width;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    @media (min-width: 1024px) {
      padding: 4rem;
    }

    .package-label {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 3rem;
    }
    .diffStyle{
      color: #A8FFEE;
    }
    h1 {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: .5rem;
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
      
    }

    .description {
      font-size: 16px;
      // line-height: 1.5;
      margin: 0;
      opacity: 0.9;
      
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      .feature {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        .icon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          stroke-width: 1.5;
          @media (max-width: 768px) {
            width: 18px;
            height: 18px;
          }
        }

        span {
          font-size: 16px;
          line-height: 1.5;
          opacity: 0.9;
        }
      }
    }

    .learn-more {
      background-color: #fff;
      color: #16A085;
      border: none;
      border-radius: 8px;
      padding: 0.75rem 1.5rem;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      width: fit-content;
      margin-top: 1rem;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
  }

  .image-section {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;

    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 768px) {
      grid-row: 1;
    }
  }
}
// Category scss

// TalentCategories.scss
.talent-categories {
  padding: 2rem 2rem;
  padding-bottom: 3rem;
  width: 100%;
  @include content-width;
  margin: 0rem 2rem;
  .title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #1a1a1a;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .subtitle {
    margin-bottom: 32px;
    color: #1a1a1a;

    .browse-link {
      color: #1F57C3;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .categories-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .category-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 24px;
    transition: box-shadow 0.3s ease;
    height: 130px;
    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .category-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
      color: #1a1a1a;
    }

    .category-stats {
      display: flex;
      align-items: center;
      gap: 16px;

      .rating {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #1a1a1a;

        .star-icon {
          width: 20px;
          height: 20px;
          fill: #28a745;
        }
      }

      .skills {
        color: #666666;
      }
    }
  }
}

// TalentDiscovery.scss

.talentDiscoveryWrapper{
  width: 100%;
  padding: 2rem;
  .talent-discovery {
    position: relative;
    font-family: "poppins";
    @include content-width;
    // min-height: 600px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('../../../assets/images/Discover.png');
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    overflow: hidden;
    padding: 1.5rem 2rem;
    color: white;
  
    .client-label {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
    }
  
    .main-title {
      font-size: 50px;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 1rem;
      @media screen and (max-width:500px) {
        font-size: 30px;
        
      }
    }
  
    .description {
      font-size: 18px;
      max-width: 460px;
      line-height: 1.5;
      // margin-bottom: 1.5rem;
    }
  
    .cards-container {
      display: flex;
      gap: 16px;
      margin-top: 1rem;
      
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
  
      }
    }
  
    .card {
      flex: 1;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 8px;
      padding: 16px 24px;
      transition: transform 0.3s ease;
      cursor: pointer;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      h2 {
        font-weight: 600;
        margin-bottom: 16px;
        @media (max-width: 500px) {
          font-size: 20px;
    
        }
      }
  
      &.publish {
        background: #1F57C3;
        color: white;
  
        .linkArrow {
          color: white;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 8px;
          a{
            font-size: 20px;
            color: white;
          }
          
        }
        &:hover{
          background-color: white;
          color: #1F57C3;
          text-decoration: none;
          .linkArrow{
            color: #1F57C3;
            a{
              color: #1F57C3;
            }
          }
          .linkarrow {
            color: #1F57C3;
            
          }
        }
      }
  
      &.solutions {
        background: #1F57C3;
        &:hover{
          background-color: white;
          color: #1F57C3;
        }
      }
  
      &.quality {
        background: #1F57C3;
        &:hover{
          background-color: white;
          color: #1F57C3;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .talent-discovery {
    .main-title {
      font-size: 40px;
    }

    .description {
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .talent-discovery {
    padding: 24px;

    .main-title {
      font-size: 32px;
    }

    .cards-container {
      flex-direction: column;
    }

    .card {
      width: 100%;
    }
  }
}

// BusinessBenefits.scss
.benefits-container {
  display: flex;
  gap: 24px;
  margin: 0 auto;
  @include content-width;
  margin: 2rem 2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.features-section {
  flex: 1;
  background-color: #DFDFDF;
  border-radius: 16px;
  padding: 2rem;
  @media screen and (max-width:500px) {
    padding: 1rem;
    
  }

  .title {
    font-size: 45px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 32px;
    color: #1a1a1a;
    padding-left: 2rem;
  }

  .features-list {
    display: flex;
    flex-direction: column;
    // gap: 24px;
  }

  .feature-item {
    display: flex;
    gap: 16px;
    padding: 0px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    @media screen and (max-width:500px) {
      padding: 0px 8px;
      
    }
    .feature-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: white;
      border-radius: 50%;
      margin-top: 10px;
    }

    .feature-content {
      flex: 1;
    }

    .feature-title {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #1a1a1a;
      @media screen and (max-width:500px) {
        font-size: 22px;
        
      }
    }
    
    .feature-description {
      font-size: 20px;
      line-height: 1.5;
      color: #515151;
      @media screen and (max-width:500px) {
        font-size: 16px;
        
      }
    }
  }
}

.rating-section {
  width: 400px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .image-container {
    height: 200px;
    overflow: hidden;
    border-radius: 0px;
    background-color: #1F57C3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .rating-content {
    background-color: #1F57C3;
    color: white;
    padding: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .rating-title {
      font-size: 38px;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 24px;
    }

    .rating-stats {
      .rating-value {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;

        .star {
          color: #ffd700;
          margin-right: 4px;
        }
      }

      .rating-description {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 1024px) {


  .features-section {
    padding: 16px;

    .title {
      font-size: 28px;
    }
  }

  .rating-section {
    .rating-content {
      padding: 24px;

      .rating-title {
        font-size: 24px;
      }
    }
  }
}

// TalentOpportunities.scss
.opportunities-container {
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  background-color: #fff;
  @include content-width;
  margin: 0rem 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.image-section {
  flex: 1;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.opportunities-container .content-section {
  flex: 1;
  background-color: #BD4B16; 
  color: white;
  padding:2rem;
  display: flex;
  flex-direction: column;
 
  .label {
    font-size: 25px;
    margin-bottom: 24px;
    @media screen and (max-width:728px) {
      font-size: 30px;
    }
    @media screen and (max-width:500px) {
      margin-bottom: 14px;
      font-size: 25px;
    }
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 16px;
    @media screen and (max-width:728px) {
      font-size: 30px;
    }
    @media screen and (max-width:500px) {
      font-size: 28px;
    }
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 40px;
    max-width: 80%;
  }

  .features-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 40px;
    border-top: 2px solid white;
    .feature-item {
      margin-top: 1rem;
      font-size: 15px;
      line-height: 1.4;
      position: relative;
    }
  }

  .opportunities-btn {
    align-self: flex-start;
    background-color: white;
    color: #BD4B16;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    a{
      color: #BD4B16;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  @media (max-width: 1024px) {
    .content-section {
      padding: 32px 24px;
  
      .title {
        font-size: 32px;
      }
  
      .description {
        max-width: 100%;
      }
    }
  }
  
  @media (max-width: 768px) {
    
  
    .content-section {
      padding: 24px 20px;
  
      .title {
        font-size: 28px;
      }
    }
  }
}



.skills-container {
  padding: 4rem 2rem;
  min-height: 30vh;
  @include content-width;
  margin: 0rem 2rem;
  width: 100%;
}

.skills-title {
  font-size: 3rem;
  color: #afafaf;
  font-weight: 600;
  cursor: pointer;
}

.trending-title {
  cursor: pointer;
  color: #afafaf;
  font-size: 3rem;
  font-weight: 600;
}

.skills-grid {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
  .active{
    color: #1F57C3;
  }
}

.skills-column {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex-wrap: wrap;
  @media screen and (max-width:768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.skills-column-two{
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (max-width:768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.skills-column-two-center{
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  
  @media screen and (max-width:768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

}
.skill-item {
  color: #333;
  font-size: 18px;
  line-height: 1.5;
  
  &:hover {
    color: #1F57C3;
    cursor: pointer;
  }
}

// Footer.scss 
.footer-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include content-width;
}
.footer-container {
  padding: 4rem 2rem;
  background-color: #181818;
  color: white;
  width: 100%;
  border-radius: 20px;
  margin: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.footer-grid {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  width: 90%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media screen and (max-width:768px) {
    justify-content: center;
    align-items: center;
  }
}
.footer-column .title{
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 16px;
  font-weight: 700;
}

.footer-item {
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.5;
  
  &:hover {
    color: #1F57C3;
    cursor: pointer;
  }
}

.footer-container .copyrightWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    width: 90%;
    h5{
      color: white;
      padding-top: 1rem;
    }
  }
}