.student-profile-box {
   
    display: flex;
    justify-content: center;
    background-color: rgba(243, 243, 243, 0.1);
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .profile {
        width: 45%;
        background: #D3EAFF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 6rem;
        padding: 6rem;
        padding-top: 15px;
        padding-bottom: 80px;

        // border: 1px solid rgba(0, 0, 0, 0.3);

        .image-box {
            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
               
            }
        }

        .info-box {
            width: 85%;
            padding: 3rem;
            padding-bottom: 20px;
            background: #FFFFFF;
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.26);
            border-radius: 20px;
            margin-top: -50px;
            margin-bottom: -50px;
            
            
           
            label {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 17px;
            }

            input::placeholder {
                font-size: 13px;
            }

            select,
            input {
                border-radius: 6px;
            }

            select {
                padding-top: 21px;
                padding-bottom: 21px;

            }

            option {
                font-size: 13px;
            }

            .title {
                display: block;
                font-size: 1.6rem;
                font-weight: 500;
            }

            .details {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .edit-btn {
                cursor: pointer;
            }

            .info {
                padding: 1rem 0;

                strong {
                    margin-right: 3rem;
                    width: 150px;
                    display: inline-block;
                }
            }

            .resume-box {
                border-top: 1px solid rgba(0, 0, 0, 0.2);
                margin: 2rem 0;
                padding: 1rem 0;

                .title {
                    margin-top: 12px;
                }

              
            }
        }
    }
}



.resume-upload {
    margin-top: 2rem;
    display: block;
    background: #4B92C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: -20px;

    &:hover {
        background-color: #357CA5;
        color: white;
    }

}

@media screen and (max-width: 640px) {
    .student-profile-box {
        .profile {
            width: 100%;
            padding: 1rem;
            padding-top: 40px;
            padding-bottom: 80px;

            .info-box {
                width: 100%;

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .student-profile-box {
        .profile {
            min-width: 600px;
        }
    }
}

.formobilescreenchaticon{
    margin-right: 3rem !important;
}

@media screen and (max-width: 450px) {
    .formobilescreenchaticon{
        margin-right: 0rem !important;
    }
}

@media screen and (min-width:1000px) {
    .profilemobilerespon{
        margin-left: 21px !important;
        width: 100%;
    }
}

