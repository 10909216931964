$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-black: #000000;
$text-gray: #7a7a7a;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.about-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .aboutHeading {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .subHeading {
        color: #000000;
        font-weight: 700;
        font-size: 35px;
    }
    .contentHeading {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 30px;
    }
    .termContentHeading{
        color: #3d3d3d;
        font-weight: 700;
        font-size: 23px;

    }
    .termContentHeading_two {
        color: #3d3d3d;
        font-weight: 500;
        font-size: 19px;
    }
    .aboutContent {
        // text-align: justify;
        color: #7a7a7a;
        font-weight: 500;
        font-size: 20px;
    }
    .termContent{
        color: #7a7a7a;
        font-size: 15px;
        
    }
    
    ul li{
        list-style-type:disc;
        color: #7a7a7a;
        font-size: 15px;
    }
    .startJourney {
        color: #1f57c3;
        font-weight: bold;
        font-size: 50px;
        margin-bottom: 2rem;
    }
    .button button {
        background-color: #1f57c3;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 20px;
        margin-right: 20px;
        a {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        .aboutHeading {
            font-size: 40px;
        }
        .subHeading {
            font-size: 25px;
        }
        .contentHeading {
            font-size: 25px;
        }
        .aboutContent {
            font-size: 17px;
            font-weight: 400;
        }
        .startJourney {
            font-size: 35px;
        }
        .termContentHeading{
            font-size: 20px;
    
        }
        .termContentHeading_two {
            font-size: 17px;
        }
        .termContent{
            font-size: 14px;
            
        }
    }
    @media screen and (max-width: 500px) {
        .aboutHeading {
            font-size: 40px;
        }
        .subHeading {
            font-size: 22px;
        }
        .contentHeading {
            font-size: 20px;
        }
        .aboutContent {
            font-size: 15px;
        }
        .startJourney {
            font-size: 30px;
        }
        .termContentHeading{
            font-size: 20px;
    
        }
        .termContentHeading_two {
            font-size: 17px;
        }
        .termContent{
            font-size: 14px;
            
        }
        .button {
            width: 100%;
            flex-direction: column;
            button {
                width: 100%;
                margin: 1rem 0rem;
            }
        }
    }
}
.contactus-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    
    .contactHeading {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .subHeading {
        color: #000000;
        font-weight: 700;
        font-size: 35px;
    }
    .contentHeading {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 30px;
    }
    .contactContent {
        // text-align: justify;
        color: #7a7a7a;
        font-weight: 500;
        font-size: 20px;
        span {
            color: $text-black;
            font-weight: 700;
        }
        a {
            color: #7a7a7a;
        }
    }
    @media screen and (max-width: 500px) {
        .contactContent {
            font-size: 15px;
        }
        .subHeading {
            font-size: 20px;
        }
       
    }
    .startJourney {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .button button {
        background-color: #1f57c3;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 20px;
        margin-right: 20px;
        a {
            color: white;
        }
    }


    .support-cards {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        margin-bottom: 2rem;
         @media screen and (max-width: 768px) {
            flex-direction: column;
        }
        .support-card {
            border-radius: 12px;
            overflow: hidden;
            margin: 2rem 0rem;
            transition: transform 0.2s ease-in-out;
            background-color: #F0F0F0;
            flex: 1;
            height: 250px;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin: 0rem 0rem;
            }
            

            &__content {
                padding: 2rem;
                display: flex;
                justify-content: space-between;
                gap: 2rem;
                height: 100%;
            }
            
            &__text {
                flex: 1.5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: 100%;
                h2 {
                    font-size: 25px;
                    font-weight: 600;
                    margin: 0 0 0.5rem 0;
                    color: #000000;
                    @media screen and (max-width: 800px) {
                        font-size: 22px;
                    }
                }
                
                p {
                    font-size: 18px;
                    line-height: 1.5;
                    color: #393939;
                    margin: 0 0 1.5rem 0;
                    @media screen and (max-width: 800px) {
                        font-size: 16px;
                    }
                }
            }

            &__link {
                display: inline-flex;
                align-items: center;
                color: #4285f4;
                text-decoration: none;
                font-weight: 500;
                gap: 0.5rem;
                transition: color 0.2s;

                &:hover {
                    color: darken(#4285f4, 10%);

                    .support-card__arrow {
                        transform: translateX(4px);
                    }
                }
            }

            &__arrow {
                width: 20px;
                height: 20px;
                transition: transform 0.2s;
            }

            &__icon {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-shrink: 0;
                flex: .5;
                margin-top: 20px;
            }
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;

            .support-card {
                &__content {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                    gap: 1.5rem;
                }

                &__icon {
                    margin-left: auto;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .aboutHeading {
            font-size: 40px;
        }
        .subHeading {
            font-size: 25px;
        }
        .contentHeading {
            font-size: 25px;
        }
        .aboutContent {
            font-size: 17px;
            font-weight: 400;
        }
        .startJourney {
            font-size: 35px;
        }
    }
    @media screen and (max-width: 500px) {
        .button {
            width: 100%;
            flex-direction: column;
            button {
                width: 100%;
                margin: 1rem 0rem;
            }
        }
    }
}


.howitwork-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    li{
        list-style: disc;
    }
    .aboutHeading {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .subHeading_2{
        font-size: 22px;
        color: #2E2E2E;
        font-weight: 500;
    }
    .subHeading {
        color: #000000;
        font-weight: 700;
        font-size: 35px;
    }
    .contentHeading {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 30px;
    }
    .howItWorkContent {
        // text-align: justify;
        color: #7a7a7a;
        font-weight: 500;
        font-size: 20px;
    }
    .startJourney {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .button button {
        background-color: #1f57c3;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 20px;
        margin-right: 20px;
        a {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        .aboutHeading {
            font-size: 40px;
        }
        .subHeading {
            font-size: 25px;
        }
        .contentHeading {
            font-size: 25px;
        }
        .howItWorkContent {
            font-size: 17px;
            font-weight: 400;
        }
        .startJourney {
            font-size: 35px;
        }
    }
    @media screen and (max-width: 500px) {
        .button {
            width: 100%;
            flex-direction: column;
            button {
                width: 100%;
                margin: 1rem 0rem;
            }
        }
    }
}

.story-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .aboutHeading {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .subHeading {
        color: #000000;
        font-weight: 700;
        font-size: 35px;
    }
    .contentHeading {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 30px;
    }
    .storyContent {
        // text-align: justify;
        color: #2E2E2E;
        font-weight: 500;
        font-size: 20px;
    }
    .startJourney {
        color: #1f57c3;
        font-weight: bold;
        font-size: 55px;
    }
    .button button {
        background-color: #1f57c3;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 20px;
        margin-right: 20px;
        a {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        .aboutHeading {
            font-size: 40px;
        }
        .subHeading {
            font-size: 25px;
        }
        .contentHeading {
            font-size: 25px;
        }
        .aboutContent {
            font-size: 17px;
            font-weight: 400;
        }
        .startJourney {
            font-size: 35px;
        }
    }
    @media screen and (max-width: 500px) {
        .button {
            width: 100%;
            flex-direction: column;
            button {
                width: 100%;
                margin: 1rem 0rem;
            }
        }
    }
}