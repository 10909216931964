.faqs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    background-color: white;

    &__title {
        font-size: 32px;
        margin-bottom: 40px;
    }

    &__section {
        margin-bottom: 40px;

        &-title {
            font-size: 45px;
            margin-bottom: 30px;
        }
    }

    &__item {
        margin-bottom: 5px;

        &--open {
            .faqs__answer {
                max-height: 1000px;
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }

            .faqs__icon img {
                transform: rotate(180deg);
            }
        }
    }

    &__question {
        width: 100%;
        text-align: left;
        padding: 20px 40px 20px 0;
        position: relative;
        background: none;
        border: none;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            color: #4a5568;
        }
    }

    &__icon img{
        transition: all 0.3s ease-out;
        width: 40px;
        height: auto;
        @media (max-width: 768px) {
            width: 20px;
        }

    }

    &__answer {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-out;

        &-content {
            padding: 0 0 20px;
            line-height: 1.6;
            font-size: 16px;
            white-space: pre-line;
        }
    }
}

@media (max-width: 768px) {
    .faqs {
        padding: 30px 15px;

        &__title {
            font-size: 28px;
        }

        &__section-title {
            font-size: 24px;
        }

        &__question {
            font-size: 15px;
            padding: 15px 30px 15px 0;
        }

        &__answer-content {
            font-size: 13px;
        }
    }
}