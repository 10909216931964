.conatiner {
  border-width: 0px;
  border-color: transparent;
  border-radius: 100%;
  padding: 2px;
  background-color: #bbbaba;
  cursor: pointer;
}

.close {
  font-size: 30px;
  border-width: 0px;
  cursor: pointer;
  
}
.close:hover {
  color: rgba(9, 125, 171, 0.998);
}
