$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.howtohire-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .hero-section {
        display: flex;
        gap: 2rem;
        padding: 2rem;
        background-color: #f5f7ff;
        border-radius: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            h1 {
                color: $primary-blue;
                font-size: 40px;
                margin-bottom: 1rem;
                font-weight: 600;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #7a7a7a;
                font-weight: 500;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.5rem;
            }
            .primary-btn {
                background-color: $primary-blue;
                color: white;
                padding: 0.8rem 4rem;
                border: none;
                border-radius: 3rem;
                font-size: 1.5rem;
                cursor: pointer;
                transition: background-color 0.3s ease;
            
                &:hover {
                    background-color: darken($primary-blue, 10%);
                }
            }
            .signup-text {
                margin-top: 1rem;
                font-size: 1.5rem;
                color: black;
                font-weight: 500;
                .signup-link {
                    color: $primary-blue;
                    text-decoration: none;
            
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .hero-image {
            flex: 1;
            img {
                width: 100%;
                height: auto;
                border-radius: 0.5rem;
            }
        }
    }

    .working-section {
        text-align: left;
        margin: 3rem 0;

        h2 {
            font-size: 30px;
            color: #333;
            margin-bottom: 1rem;
            font-weight: 700;
        }

        p {
            color: $text-dark;
            line-height: 1.3;
            width: 60%;
            font-size: 16px;
        }
    }

    .job-post-section {
        display: flex;
        gap: 3rem;
        margin: 3rem 0;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .job-post-image {
            flex: 1;
            img {
                width: 100%;
                height: 80%;
                object-fit: cover;
                border-radius: 1rem;
            }
        }

        .job-post-content {
            flex: 1;

            h2 {
                font-size: 40px;
                color: $text-dark;
                margin-bottom: 1rem;
                font-weight: 700;
                margin-top: 2rem;
            }

            h3 {
                font-size: 30px;
                color: #3c3c3c;
                margin-bottom: 1rem;
                @media (max-width: 1024px) {
                    font-size: 1.8rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1rem;
                line-height: 1.6;
                font-size: 1.5rem;
                font-weight: 400;
            }
            .primary-btn {
                background-color: $primary-blue;
                color: white;
                padding: 0.8rem 4rem;
                border: none;
                border-radius: 3rem;
                font-size: 1.5rem;
                cursor: pointer;
                transition: background-color 0.3s ease;
            
                &:hover {
                    background-color: darken($primary-blue, 10%);
                }
            }
        }
    }
}





// Approval Section
.approval-section {
    transform: translateY(-80px);
    @media (max-width: 1024px) {
        transform: translateY(-40px);
    }
    @media (max-width: 768px) {
        transform: translateY(0px);
    }
    h2{
        font-size: 3rem;
        font-weight: 600;
    }
    .approval-content {
        margin-top: 3rem;
        display: flex;
        gap: 2rem;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .approval-intro {
            flex: 1;
            color: #262626;
        }

        .approval-list {
            flex: 1;
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 1rem;
                color: #000000;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                &::before {
                    color: #28a745;
                }
            }
        }
    }
}

// Safety Section
.safety-section {
    display: flex;
    gap: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .safety-image {
        flex: 1;

        img {
            width: 100%;
            height: 90%;
            border-radius: 1rem;
            object-fit: cover;
            @media (max-width: 768px) {
                width: 100%;
                height: auto;
            }
        }
    }

    .safety-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2rem;
        @media (max-width: 1024px) {
            margin-top: 0rem;
        }
        @media (max-width: 768px) {
            margin-top: 1rem;
        }
        .safety-guarantee,
        .location-info {
            h2{
                font-size: 40px;
                @media (max-width: 1024px) {
                    font-size: 30px;
                }
                @media (max-width: 768px) {
                    font-size: 2.4rem;
                }
            }
            h3 {
                color: #3C3C3C;
                font-size: 30px;
                margin-bottom: 1.5rem;
                @media (max-width: 1024px) {
                    font-size: 24px;
                }
                @media (max-width: 768px) {
                    font-size: 1.7rem;
                }
            }
            p{
                font-size: 1.5rem;  
                color: #7A7A7A;              
            }
        }

        .safety-details {
            margin-top: 1rem;
        }
    }
}

// FAQ Section
.faq-section {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    h2{
        font-size: 3.5rem;
        font-weight: 700;
        width: 45%;
        @media (max-width: 1024px) {
            font-size: 2.5rem;
        }
        @media (max-width: 768px) {
            width: 100%;
            font-size: 2rem;
        }
    }

    .faq-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 55%;
        @media (max-width: 768px) {
            margin-top: 2rem;
            width: 100%;
        }
        .faq-item {
            padding-bottom: 1rem;
            border-bottom:1.5px solid #9D9D9D ;
            margin-bottom: 1rem;
            &:last-child{
                border-bottom: none;
              }
            h3 {
                font-size: 2rem;
                color: #333;
                margin-bottom: 0.5rem;
                font-weight: bold;
                @media (max-width: 768px) {
                    font-size: 1.7rem;
                }
            }

            p {
                margin: 0;
            }
        }
    }
}
