.arrowNavLeft {
  position: absolute;
  top: 79%;
  transform: translateY(50%);
  z-index: 1; /* Ensure arrows are above the slider content */
  cursor: pointer;
  border-radius: 100px;
  width: 22px;
  height: 22px;
  border: none;
  color:white;
  background-color: #4b92c8;
  margin-left: 47.5%;

}
.arrowNavRight {
  position: absolute;
  top: 79%;
  transform: translateY(50%);
  z-index: 1; /* Ensure arrows are above the slider content */
  cursor: pointer;
  border-radius: 60px;
  width: 22px;
  height: 22px;
  border: none;
  color: white;
  background-color: #4b92c8;
  margin-left: 50.5%;

}



.arrowNavLeft:hover{
  background-color: #59aae9;
}


.arrowNavRight:hover{
  background-color: #59aae9;
}





@media screen and (max-width: 1150px) {
  .arrowNavLeft {
    margin-left: 46.3%;
  }

  .arrowNavRight {
    margin-left: 50.3%;
  }
}


@media screen and (max-width: 722px) {
  .arrowNavLeft {
    margin-left: 44.3%;
  }

  .arrowNavRight {
    margin-left: 50.3%;
  }
}


@media screen and (max-width: 591px) {
  .arrowNavLeft {
    margin-left: 43.2%;
  }

  .arrowNavRight {
    margin-left: 50.7%;
  }
}
