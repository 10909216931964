.headingText {
    max-width: 950px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    h1{
        font-size: 30px;
        
        @media screen and (max-width:768px) {
            font-size: 24px;
        }
        @media screen and (max-width:500px) {
            font-size: 22px;
        }
    }
    
}
.content {
    .wfm1 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #303030;
    }
}
.lastbutton {
    background: #2ba4f5;
    border-radius: 5px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        background: #3097dc;
        color: white;
    }
}

.reviewpage1 {
    background-color: rgba(224, 220, 243, 0.212);

    .r1 {
        .sectionn11 {
            background: white;
            box-shadow: 0px 0px 15px 0px #00000025;

            border-radius: 6px;
            //  border: solid 1px rgb(214, 214, 214);
            margin: 30px;
            max-width: 950px;
            padding: 3rem 5rem;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: 768px) {
                padding: 2rem 3rem;
                
            }
            @media screen and (max-width: 500px) {
                padding: 2rem 2rem;
                
            }
            .buttonContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                // @media screen and (max-width:400px) {
                //   flex-direction: column;
                //   gap: 1rem;
                // }
            }
            .post {
                background: #ffffff;
                border-radius: 6px;
                padding-left: 8px;
                padding-right: px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 13px;
                font-weight: bold;
                color: #4b92c8;
                border-color: lightskyblue;
            }

            .post1234 {
                background: #f5f5f5;
                border-radius: 20px;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 4px;
                padding-bottom: 4px;
                font-size: 14px;
                color: #535353;
                display: inline-block; /* Adjust width according to content */
            }

            .heeading {
                text-transform: uppercase;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.5px;
                color: #7e7e7e;
            }

            .head {
                margin-top: -3px;
                margin-left: 5px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #303030;
            }

            .content {
                .color {
                    background-color: blue;
                }
                .wfm1 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #303030;
                }

                .lastbutton {
                    background: #2ba4f5;
                    border-radius: 5px;
                    color: white;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    font-size: 16px;
                    font-weight: 500;

                    &:hover {
                        background: #3097dc;
                        color: white;
                    }
                }
            }

            .title {
                .icon {
                    font-size: 12px;

                    i {
                        color: #0077e4;
                    }
                }

                .btntouch {
                    background: #fffaeb;
                    border-radius: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 19px;
                    color: #9e7357;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .btntouch:hover {
                    background-color: #9e7357;
                    color: #fffaeb;
                }
            }

            .companynamee {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #313131;
                line-height: 25px;
            }
        }

        .section2 {
            border: 1px solid #b1b1b1;
            background: #ffffff;
            border-radius: 15px;
            box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.26);

            .reviewpagebtn {
                .btnrevie {
                    background: #ffffff;
                    border: 1px solid #c1c1c1;
                    border-radius: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .information {
                .Internship {
                    border-bottom: 1px solid #b6b6b6;
                    padding-bottom: 7px;

                    .internname {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: #3d3d3d;
                    }
                }

                .Education {
                    border-bottom: 1px solid #b6b6b6;
                    padding-bottom: 7px;
                }

                .titlee {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #6d6d6d;
                }

                .eduname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3d3d3d;
                }

                .clgname,
                .schlname,
                .year,
                .board,
                .percantage {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 22px;
                    color: #5f5f5f;
                }
            }

            .heading {
                background: #d3eaff;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;

                .aplied {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 19px;

                    color: #676767;
                }
            }

            .cover {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #3d3d3d;
            }

            .hire {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #3d3d3d;
            }

            .para {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #5f5f5f;
            }
        }
    }
}

.wfm123 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #535353;
}

.post1 {
    background: #eeeeee;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    color: #696969;
    cursor: auto;
}
.responsive-col {
    width: 75%; /* col-9 equivalent */
}

@media (max-width: 576px) {
    .responsive-col {
        width: 100%; /* col-8 equivalent */
    }
}
