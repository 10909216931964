  ul li {
  list-style: none;
  text-decoration: none;
}

ul li a {
  color: #484848;
  text-decoration: none;
  padding-left: 0;
  display: block;
  // margin-top: 10px;
  // margin-bottom: 10px;
  font-weight: 500;
}

ul li a:hover {
  text-decoration: none;
  color: #008bdc;
}

.hover-item {
  font-weight: 500;
  color: #484848;
}
.hover-item:hover {
  color: #008bdc;
}

.user-profile-pic {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.user-name {
  font-weight: 600;
  font-size: 16px;
  //cursor: pointer;
}
