.filters-container {
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #333;
        font-size: 14px;
        line-height: 1.57;
        font-weight: 400;

        .filter-type {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 16px;
        }

        .parent-dropdown2 {
            position: relative;
            padding: 2px 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border: 1px solid #eee;

        }

        .parent-dropdown2:hover,
        .parent-dropdown:focus {

            border: 1px solid #00a5ec;

        }

        .filter-type input,
        .filter-type .date-input {
            width: 100%;
            outline: none;
            box-shadow: none;
            background-color: white;
            color: #484848;
            border: 1px solid #ddd;
            border-radius: 3px;
            padding: 8px 12px;
        }

        .date-input {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            height: auto;
        }

        .dates {
            width: fit-content;
            height: auto;
            background-color: #00a5ec;
            color: white;
            padding: 4px 10px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            text-transform: capitalize;
        }

        .filter-type input:hover,
        .filter-type input:focus,
        .filter-type .date-input:focus,
        .filter-type .date-input:hover {
            border: 1px solid #00a5ec;

        }

        .drop-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .dropdown {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 3px;
            background: white;
            height: fit-content;
            max-height: 200px;
            position: absolute;
            width: 100%;
            top: 44px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            z-index: 100;
            padding: 8px 12px;
            overflow-y: auto;
            float: left;
            text-align: left;
            color: #212529;
            font-weight: 900;
        }

        .togglers {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        .button-section {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 6px;
            font-size: 14px;
            font-weight: 500;
            margin-top: 16px;
        }

        .button-section button {
            transition: all 0.3s;
            padding: 6px 12px;
            border: 0;
            outline: none;
            border-radius: 4px;
        }


        .button-section> :first-child:hover {
            background-color: #eef9fd
        }

        .button-section> :last-child:hover {
            background-color: #056f9c
        }

        .button-section> :first-child {
            color: #00a5ec;
            background-color: white;
        }

        .button-section> :last-child {
            color: white;
            background-color: #00a5ec;
        }

        .button-section>:first-child:active {
            background-color: rgba(156, 144, 144, 0.171)
        }

        .button-section>:last-child:active {
            background-color: rgba(156, 144, 144, 0.171)
        }


    }
}