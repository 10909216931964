.appliedjobBody{
    background-color: white;
}



.btn-info02{
    height: 50px;
    background-color: #2fa1ee;
    color: white;
    letter-spacing: 0.4px;
    font-weight: 500;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgb(30, 133, 223);
        color: white;
    }
}


.accordianfortab{
    background-color: #f1fdff;
    border: solid 1px rgb(60, 226, 252);
    box-shadow: none;
    border-radius: 7px;
    margin-top: -10px;
}


