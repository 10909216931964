$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-black: #000000;
$text-gray: #7a7a7a;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.anyhire-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .anyhire-hero-section {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        padding-top: 3rem;
        padding: 2rem;
        background-color: #1f56c321;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: $primary-blue;
                font-size: 45px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: $text-gray;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.8rem;
                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }

    h1,
    h2,
    h3 {
        margin: 0;
        line-height: 1.2;
    }

    // Hero Section
    .globalTalent {
        margin-bottom: 4rem;

        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4rem;
            margin-top: 2rem;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 2rem;
            }
        }

        &__left {
            h1 {
                font-size: 5rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
        }

        &__highlight {
            display: block;
            color: #1a73e8;
        }

        &__subtitle {
            font-size: 2.5rem;
            color: #393939;
            font-weight: 600;
        }

        &__description {
            font-size: 20px;
            line-height: 1.6;
            color: #000000;
            margin-bottom: 1.5rem;
            font-weight: 500;
        }
        .avatar-stack {
            display: flex;
            margin-bottom: 1rem;

            .avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 2px solid white;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .growBusiness {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 4rem;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        h2 {
            flex: 1;
            font-size: 45px;
            font-weight: 600;
            @media (max-width: 768px) {
                font-size: 2.8rem;
            }
        }

        &__right {
            flex: 1;
            padding-left: 1rem;
            p{
                color: #7a7a7a;
                font-size: 20px;
            }
        }
    }

    // Grow Section
    .grow {
        margin: 3rem 0rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4rem;
            align-items: flex-start;
            // justify-content: flex-start;
            h3{
                font-size: 23px;
            }
            p{
                font-size: 16px;
                color: #3d3d3d;
                font-weight: 500;
            }
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 2rem;
            }
        }

        &__image {
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 8px;
            }
        }

        &__text {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
        }
    }

    .benefits {

        &__content {
            background-color: #E9EFF9;
            padding: 1rem;
            border-radius: 8px;
            margin-bottom: 2rem;

            h3 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }

            p {
                margin-bottom: 1.5rem;
                line-height: 1.6;
            }
        }

        &__list {
            display: grid;
            gap: 1rem;
        }

        .benefit {
            font-weight: 500;
            color: #333;
            font-size: 2rem;
            @media (max-width: 768px) {
                font-size: 1.6rem;
            }
        }
    }

    // Process Section
    .process {
        h2 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 2rem;
            @media (max-width: 768px) {
                font-size: 2.8rem;
            }
        }

        &__steps {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
        }

        &__card {
            background-color: #F0F0F0;
            padding: 2rem;
            border-radius: 20px;

            h3 {
                font-weight: 500;
                color: #000000;
                font-size: 1.8rem;
                @media (max-width: 768px) {
                    font-size: 1.6rem;
                }
            }

            p {
                color: #3d3d3d;
                font-weight: 500;
                margin-top: 1rem;
            }
        }
    }

    // Button Styles
    .button {
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s;

        &--primary {
            background-color: #1a73e8;
            color: white;

            &:hover {
                background-color: darken(#1a73e8, 5%);
            }
        }
    }
}
