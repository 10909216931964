.profile-section123 {
  margin: 0px auto;
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 24px;
    background-color: #00a5ec;
    border-radius: 100%;
  }
  .notactive {
    color: rgb(252, 252, 252);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 24px;
    background-color: #bbbbbb;
    border-radius: 100%;
  }
  .notactiveBar{
    background-color: #bbbbbb;
    width: 120px;
    height: 4px;
  }
  .activeBar{
    background-color: #00a5ec;
    width: 120px;
    height: 4px;
  }

  .sub-section123 {
    padding: 20px 30px 30px 30px;
    background-color: white;
    box-shadow: 0px 0px 15px 0px #00000025;

    border: none !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    font-size: 14px;

    .details {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      .title {
        font-size: 14px;
        color: #333;
        font-weight: 500;
        font-family: "Inter var", sans-serif;
      }

      input,
      textarea {
        padding: 8px 10px;
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        outline: none;
      }

      input:focus,
      textarea:focus {
        border: 1px solid #00a5ec;
      }
    }
  }

  .website {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #f8f8f8;
    margin-top: 20px;

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
      color: #707070;
      font-family: "Inter var", sans-serif;
    }
  }

  .website a {
    text-decoration: none;
    color: #00a5ec;
    font-weight: 500;
    transition: all 0.3s;
  }

  .website a:hover {
    color: #048cc7;
  }

  .image-container {
    border: 1px solid #ddd;
    object-fit: cover;
    width: fit-content;
  }

  .hidden-input {
    display: none;
  }

  .custom-label {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.142;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0px;
    background-color: #eafcff;
    border: 1px solid #008bdc;
    color: #008bdc;
    border: dashed 1px;
    cursor: pointer;
  }

  .custom-labeldesabled {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.142;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0px;
    background-color: #e7e7e7;
    border: 1px solid #5f5f5f;
    color: #5f5f5f;
    border: dashed 1px;
  }

  .submit-btn123 {
    background-color: #00a5ec;
    border: 1px solid #00a5ec;
    outline: none;
    transition: 0.3s;
    color: white;
    padding: 3px 18px;
    border-radius: 2px;
    width: fit-content;
    margin-top: 20px;
    margin-left: auto;
  }

  .submit-btn123:hover {
    background-color: #048cc7;
  }

  .submit-btn123:active {
    background-color: black;
  }
}

@media screen and (min-width: 992px) {
  .profile-section123 {
    width: 40%;
  }
}

.input-text2-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  background-color: white;
  border: 1px solid #ddd;
  margin-top: 4px;
  padding-left: 10px;
}

.input-text2 {
  outline: none !important;
  border: 0px solid #ddd;
  background-color: white;
  width: 100%;
}
