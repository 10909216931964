$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.agency-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    min-height: 100vh;
    .agency-hero-section {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        padding: 2rem;
        background-color: #1f56c321;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: #1f57c3;
                font-size: 45px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.5rem;
                font-weight: 500;
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }
    .agency-categories-container {
        .main-title {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 2.5rem;
            text-align: left;
        }

        .categories-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 1.5rem;
            margin-bottom: 4rem;
        }

        .category-card {
            background-color: #f5f5f5;
            border-radius: 1.5rem;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
            }
            &:hover {
                transform: translateY(-2px);
                transition: transform 0.2s ease;
            }
        }

        .category-title {
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        .services-list {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .service-item {
            color: #000000;
            font-size: 1.4rem;
        }
    }

    // How it works section
    .how-it-works {
        margin: 4rem 0;

        h2 {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 2rem;
        }
    }

    .steps-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 2rem;
    }

    .steps-container .step-item {
        display: flex;
        gap: 2rem;
    }
    .step-item .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 1.2rem;
        }
    }
    .step-number {
        width: 3rem;
        height: 3rem;
        background-color: #000;
        color: white;
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    // Agency search section
    .agency-search {
        margin-top: 4rem;

        h2 {
            font-size: 1.75rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
    }

    .subtitle {
        color: #666;
        margin-bottom: 2rem;
    }

    .categories-section {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .main-categories {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .category-link {
        color: #666;
        font-size: 1rem;
        cursor: pointer;

        &.active {
            color: #0066cc;
            font-weight: 600;
        }

        &:hover {
            color: #0066cc;
        }
    }

    .services-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
    }

    .service-link {
        color: #333;
        font-size: 0.9rem;
        cursor: pointer;

        &:hover {
            color: #0066cc;
        }
    }

    @media (max-width: 768px) {
        .categories-grid {
            grid-template-columns: 1fr;
        }

        .steps-container {
            grid-template-columns: 1fr;
        }

        .services-grid {
            grid-template-columns: 1fr;
        }
    }
    .faq-section {
        background-color: #f8f9fa;
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        margin-top: 4rem;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        h2 {
            font-size: 3.5rem;
            font-weight: 700;
            width: 45%;
            @media (max-width: 1024px) {
                font-size: 2.5rem;
            }
            @media (max-width: 768px) {
                width: 100%;
                font-size: 3rem;
            }
        }

        .faq-list {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 55%;
            @media (max-width: 768px) {
                margin-top: 2rem;
                width: 100%;
            }
            .faq-item {
                h3 {
                    font-size: 30px;
                    color: #333;
                    margin-bottom: 0.5rem;
                    font-weight: bold;
                    @media (max-width: 1024px) {
                        font-size: 25px;
                    }
                    @media (max-width: 768px) {
                        width: 100%;
                        font-size: 20px;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .search-agencies-by-category {
        padding-top: 2rem;
        // margin-bottom: 4rem;
        min-height: max-content;
        h2 {
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }
        p {
            font-size: 2rem;
            color: #515151;
            font-weight: 600;
        }
        .displayCategoriesWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 3rem;
            @media (max-width: 900px) {
                flex-direction: column;
                height: 100%;
            }
            .categories {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                width: 40%;
                gap: 0.5rem;
                color: #afafaf;
                margin-bottom: 2rem;
                height: 100%;
                @media (max-width: 900px) {
                    width: 100%;
                }
                @media (max-width: 900px) {
                    flex-direction: row;
                    overflow-x: scroll;
                }
                .category {
                    padding: 0.5rem 0rem;
                    border-radius: 0.5rem;
                    cursor: pointer;
                    h1 {
                        font-size: 27px;
                        @media (max-width: 1024px) {
                            font-size: 24px;
                        }
                        @media (max-width: 900px) {
                            font-size: 20px;
                            width: max-content;
                            margin-right: 2rem;
                        }
                    }
                    &.active {
                        color: #1f57c3;
                    }
                }
            }
            .skills {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                flex-wrap: wrap;
                color: #3d3d3d;
                gap: 0rem 1rem;
                padding-left: 2rem;
                @media (max-width: 900px) {
                    width: 100%;
                    padding-left: 0rem;
                }
                h3 {
                    font-size: 19px;
                    margin-bottom: 1.3rem;
                    font-weight: 400;
                    &:hover {
                        color: #1f57c3;
                    }
                    @media (max-width: 1024px) {
                        font-size: 20px;
                    }
                    @media (max-width: 900px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
