$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.hire-worldwide-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .hero-section {
        display: flex;
        gap: 2rem;
        padding: 2rem;
        background-color: #f5f7ff;
        border-radius: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            h1 {
                color: $primary-blue;
                font-size: 45px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }
            .signup-text {
                margin-top: 6rem;
            }
            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 16px;
                font-weight: 500;
            }

            .inputBox {
                width: 90%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: 768px) {
                    width: 100%;
                }

                p {
                    margin: 0rem;
                }
                .searchBtn {
                    background-color: #1d4ed8;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    transform: translateX(-50px);
                    .searchIcon {
                        width: 20px;
                        height: auto;
                    }
                }
            }
        }

        .hero-image {
            flex: 1;
            img {
                width: 100%;
                height: auto;
                border-radius: 0.5rem;
            }
        }
    }

    .how-it-works {
        margin: 4rem 0;

        h2 {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 2rem;
        }
        .buttons {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            @media screen and (max-width: 500px) {
                flex-direction: column;
            }
            button {
                padding: 1rem 3rem;
                background-color: #1f57c3;
                color: white;
                border: none;
                margin-right: 1rem;
                border: 2px solid transparent;
                border-radius: 10px;
                font-weight: 500;
                font-size: 20px;
                @media screen and (max-width: 500px) {
                    margin: 1rem 0rem;
                    width: 100%;
                }
            }
            .active {
                padding: 1rem 3rem;
                border: 2px solid #1f57c3;
                background-color: white;
                font-size: 20px;
                color: black;
                font-weight: 500;
            }
        }
        .otherContent {
            font-size: 20px;
            margin-top: 2rem;
            font-weight: 500;
            color: black;
        }
    }

    .steps-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 2rem;
    }
    .step-item {
        background-color: #f0f0f0;
        padding: 2rem;
        border-radius: 1rem;
    }

    .steps-container .step-item {
        display: flex;
        gap: 2rem;
    }
    .step-item .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        h3 {
            font-size: 25px;
            @media screen and (max-width: 800px) {
                font-size: 20px;
            }
        }
        p {
            font-size: 18px;
            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
        }
    }
    .step-number {
        width: 3rem;
        height: 3rem;
        // background-color: #000;
        color: white;
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .howoitwork_accordion-item {
        display: flex;
        flex-direction: column;
        gap: 1px;
        background: #fff;
        overflow: hidden;
    }

    .howoitwork_accordion-item {
        background: #fff;
        border-bottom: 2px solid #6a6a6a;
        border-radius: 0px;

        &.active {
            .arrow-icon {
                transform: rotate(180deg);
            }

            .howoitwork_accordion-content {
                max-height: 2000px;
                padding: 20px 20px 20px 44px; // Added left padding to align with title
                .popularSkills {
                    color: black;
                    font-size: 16px;
                    margin-bottom: 3rem;
                }
                @media screen and (max-width: 500px) {
                    padding: 20px;
                }
            }
        }
    }

    .howoitwork_accordion-button {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        background: none;
        border: none;
        cursor: pointer;
        text-align: left;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #f8f9fa;
        }

        .howoitwork_arrow-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            color: #666;
            padding: 1rem;
            background-color: #cbcbcb;
            border-radius: 50%;
            transition: transform 0.3s ease;
            img {
                width: 25px;
                height: auto;
                object-fit: cover;
            }
        }

        .howoitwork_accordion-title {
            font-size: 25px;
            font-weight: 500;
            color: #333;
            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }
    }

    .howoitwork_accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .howoitwork_services-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 12px;

        @media (max-width: 768px) {
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        }

        @media (max-width: 480px) {
            grid-template-columns: 1fr;
        }
    }

    .howoitwork_service-item {
        padding: 12px 16px;
        background: #f8f9fa;
        border-radius: 6px;
        font-size: 18px;
        color: #555;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
            background: #f0f0f0;
            transform: translateY(-1px);
        }
    }
}

.primary-btn {
    background-color: $primary-blue;
    color: white;
    padding: 0.8rem 3rem;
    border: none;
    border-radius: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($primary-blue, 10%);
    }
}
