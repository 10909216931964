.message-employer {
    .recent-chats {
        .recent-chats-title {
            background-color: #D6D6D6 !important;
            padding: 1rem;
        }

        .select-box {
            background-color: #D6D6D6;
            padding: 1rem;
        }

        select {
            width: 100%;
            padding: .6rem 1rem;
            background-color: transparent;
            margin: .5rem 0;
        }
    }

    .chatroom {
        .sender-area {
            .profile {
                display: flex;
                align-items: center;

                * {
                    padding: .5rem;
                }

                strong {
                    font-size: 2.2rem;
                }

                span {
                    font-size: 2rem;
                    margin-top: 0 !important;
                }
            }
        }

        .message-section {
            .conversations {
                .related-messages {
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;

                    .chip {
                        padding: 1rem;
                        margin: 0 1rem;
                        font-size: 1.4rem;
                        border-radius: 10px;
                        background-color: rebeccapurple;
                        cursor: pointer;

                        &.hire {
                            background-color: rgba(56, 197, 43, 0.12);
                            color: #38c52b;
                        }

                        &.ni {
                            background-color: rgba(202, 28, 28, 0.12);
                            color: #ca1c1cc0;
                        }

                        &.si, &.sa {
                            color: #0077E4;
                            background: rgba(0, 119, 228, 0.12);
                        }
                    }
                }
            }
        }
    }
}