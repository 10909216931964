.maincheck {
    .btngroup {
        .intern {
            background: #FFFFFF;
            border: 1px solid #0077E4;
            border-radius: 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 7px;
            padding-bottom: 7px;
            color: #0077E4;
            margin-top: 150px;
        }
        .job {
            background: #0077E4;
            border: 1px solid #0077E4;
            border-radius: 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            padding-left: 35px;
            padding-right: 35px;
            padding-top: 7px;
            padding-bottom: 7px;
            color: #FFFFFF;
            z-index: 1;
            position: relative;
            right: 20px;
            margin-top: 150px;
        }
    }
}
.maintable{
    overflow: auto;
    background: #FFFFFF;
 
    width: 100%;
  
    .three-dots-menu {
        position: relative;
        margin-left: 70px;
      }
      
      .menu-icon {
        cursor: pointer;
      }
      
      .dot {
        border-radius: 50%;
        width: 3px;
        height: 3px;
        margin: 2px;
        background: #616161;
      }
      .menu {
        position: absolute;
        top: 30px;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
        padding: 10px;
        z-index: 1;
       
      }
      
      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .menu li {
        margin: 5px 0;
        line-height: 30px;
        cursor: pointer;
      }
      
      .menu li:hover {
        color: black;
        background: #D3EAFF;              }
      
   
    td,
    th {
        padding: 2rem;
        font-size: 1.2rem;
    }

    th {
        
        padding: 2.9rem;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #505050;
    }

    th {
        background: #D3EAFF;
    }

    td {
        padding: 3rem;
        &:last-child {
            border-right: none;
        }
    }

    // tr:last-child {
    //     td {
    //         border-top: 1px solid rgba(0, 0, 0, 0.2);
    //     }
    // }

    td {
        height: 60px;

    }
}