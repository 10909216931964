.terms-of-use{

  ul{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    li{
      list-style: disc;
      margin-bottom: 9px;
    }
  }

}
@media screen and (max-width: 910px) {
    .about-us-mobile-responsive {
      width: 88vw;
        text-align: justify;
    }
    
  }