.view-more-internship {
    display: flex;
    justify-content: center;
    padding: 5rem 2rem;
   // background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)), url("../../../assets/images/internship.jpg");
    background-color: rgb(221, 242, 255) ;
    background-size: 100% 160%;
    opacity: 0.8;
    padding-bottom:5rem ;

    .job{
        color: white;
        background: #4B92C8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-left: 120px;
        padding-right: 120px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .job-form {
        min-width: 300px;

        .input-box {
            width: 100%;
        }
    }

    .input-box {
        padding: 2rem 0;
        position: relative;

        input {
            border: 1px solid rgba(0, 0, 0, .2);
            padding: 1rem 3.4rem;
            width: 100%;
            outline: none;
        }

        svg {
            &:first-child {
                position: absolute;
                left: 0;
                top: 0;
                margin: 2.85rem .8rem;
            }
        }
    }
}

.job-by-profession {
    margin: 0 10rem;
    padding: 3rem 1rem;
    padding-bottom: 12rem !important;
    
    .title {
        display: inline;
        padding: 1rem;
      
      
    }
        .profession
        {
        img {
            width: 280px;
     
    }
}
}

.btn-lg {
    font-size: 15px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #4B92C8;
    color: white;
    border-radius: 15px;

    &:hover {
        background-color: #357CA5; 
        color: white; 
    }
}

/* Add hover effect to job boxes */
.profession {
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.05); 
      cursor: pointer;    
    }
  }
  



@media screen and (min-width: 460px) {
    .job-by-profession {
        padding: 3rem 8rem;
    }
}


.findJobBtn{
    background: #1677c2;
    border-radius: 15px;
    color: white;
    border: none;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 16px;
    font-weight: 400;
}

.findJobBtn:hover{
    background: #1163a1; /* Change the background color on hover */
}