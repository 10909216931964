.message-student {
  // padding: 40px 10px !important;

  .chat-box-1 {
    // margin: 0px 3rem;
    // max-width: 1100px;
    display: flex;
    background: #ffffff;
    border: 1px solid #cbcbcb;

    .recent-chats {
      display: flex;
      flex-direction: column;
      background-color: blue;
      width: 40%;

      .tabss {
        height: 68px;
        border-bottom: 1px solid #cbcbcb;
        background: #ffffff;
        gap: 2rem;
      }

      .btn3 {
        background: #4b92c8;
        border-radius: 20px;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        font-size: 14px;
      }

      .btn4 {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 30px;
        padding-left: 35px;
        padding-right: 35px;
        position: relative;
        font-size: 14px;
        margin-left: -40px;
      }

      .chats {
        background: #ffffff;
        height: 100%;
        overflow: auto;
        
        .chat {
          padding: 2rem;
          border-bottom: 1px solid #d6d6d6;
          border-right: 1px solid #d6d6d6;

          position: relative;
          transition: 0.3s ease-out;
          display: block;
          text-decoration: none;

          

          &:hover {
            background-color: #ccc;
          }

          .date {
            position: absolute;
            top: 2rem;
            right: 2.5rem;
            font-size: 1.3rem;
          }

          .status {
            background: #d3eaff;
            border-radius: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            padding-left: 18px;
            padding-right: 18px;
            padding-top: 2px;
            padding-bottom: 2px;

            color: #585858;
          }

          .title,
          .message {
            display: block;
          }

          .title {
            font-size: 1.5rem;
          }

          .message {
            margin-top: 1rem;
            font-size: 1.4rem;
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .recent-chats {
        display: block; /* Show recent chats in a block on small screens */
        width: 100%;
      }

      .chatroom {
        display: none; /* Hide chatroom on small screens initially */
      }

      .hide-chat-list .recent-chats {
        display: none; /* Hide recent chats when viewing a chat on small screens */
      }

      .hide-chat-list .chatroom {
        display: block; /* Show chatroom when viewing a chat on small screens */
      }
    }

    .chatroom {
      width: 67%;
      background: #ffffff;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;

      // display: flex;
      // flex-direction: column;

      .sender-area {
        
        background: #ffffff;
        padding: 1rem;
        border-left: 1px solid #cbcbcb;
        border-bottom: 1px solid #cbcbcb;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .profile {
          strong,
          span {
            display: block;
          }

          strong {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .message-section {
        display: flex;
        flex-direction: column;
        height: 89%;
        .hire {
          background: #d9ead7;
          border-radius: 5px;
          color: #38c52b;
          padding-left: 17px;
          padding-right: 17px;
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .int {
          background: #ffdede;
          border-radius: 5px;
          color: #ca1c1c;
          padding-left: 17px;
          padding-right: 17px;
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .schdeule {
          background: #d2e1ee;
          border-radius: 5px;
          color: #0077e4;
          padding-left: 17px;
          padding-right: 17px;
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .assign {
          background: #d2e1ee;
          border-radius: 5px;
          color: #0077e4;
          padding-left: 17px;
          padding-right: 17px;
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      .conversations {
        padding: 1rem;
        height: 74%;
        overflow: auto;

        .date-time {
          padding: 1rem;
          text-align: center;
          font-size: 1.4rem;
        }

        .conversation {
          display: flex;
          margin: 2rem;

          p {
            border: 1px solid #d6d6d6;
            max-width: 60%;
            padding: 1rem;
            border-radius: 15px;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.3px;
          }

          &.left {
            justify-content: flex-start;

            p {
              background: #f4f4f4;
              box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }

          &.right {
            justify-content: flex-end;
            
            p {
              background: #d3eaff;
              box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }
        }
      }

      .send-message-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20%;
        max-height: 80px;
        padding: 4rem 2rem;
        gap: 2rem;
        border-top: 1px solid #d6d6d6;
        border-bottom-right-radius: 6px;
  
        .btnsend {
          width: 80px;
          background: #bbb;
          border: 1px solid #bbb;
          border-radius: 3px;
          color: #fff;
          padding: 11px 15px;
          font-size: 14px;
          line-height: 16px;
        }
  
        .btnsend:hover {
          background: #77c2ff;
        }
  
        input {
          width: 100%;
          padding: 1rem 1.3rem;
          border-radius: 3px;
          outline: none;
          border: 1px solid #d6d6d6;
        }
  
        div input:hover {
          border: 1px solid #77c2ff;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .chat-box {
      width: 100%;
    }
  }
}


@media only screen and (min-width: 1000px) {
  .ChatMobView {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {

  .ChatPcView {
    display: none;
  }
  .ChatMobView {
    // margin-bottom: -40px;
  }
  .recent-chats {
    width: 100% !important;
}

.chatroom {
  width: 100% !important;
}



.message-student {
  padding: 0px 0px !important;

}


}


@media screen and (max-width: 800px) {
  .btn3 {
    display: none;
  }

  .btn4 {
    display: none;
  }

  .date {
    display: none;
  }
}
