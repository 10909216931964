.btn-info02{
    border-radius: 8px;
    height: 40px;
    width: 175px;
    font-size: 13px;
    background-color: #4ea6cf;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgb(59, 137, 182);
        color: white;
    }
}


.section1{
    box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
    -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
    0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
    0 -3px 8px -3px rgba(233, 233, 250, 0.808);
    background: #FFFFFF;
    border-radius: 15px;
    border: "none";
}