.drop-Down {
  width: 300px;
  margin-bottom: 10px;
}
.project-details-conatiner {
  border: 1px solid #d1d1d1;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  margin-top: 45px;
}

.rate-conatainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width:500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.budget-input {
  outline: none;
  border: 1px solid rgb(188, 186, 186);
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.heeading {
  // text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #7e7e7e;
}
.post1234 {
  background: #f5f5f5;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  color: #535353;
  display: inline-block; /* Adjust width according to content */
}

.submit-btn {
  background-color: #006bc2;
  color: white;
  border-radius: 20px;
}
.submit-bt:hover {
  color: white;
  border-radius: 20px;
}
.my-applications {
  background-color: rgba(224, 220, 243, 0.212);
  
  // background-color: white !important;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  padding: 6rem 1rem;
  min-height: 60vh;
  
  .tableWrapper{
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
  
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 20px;
    color: #2b2b2b;
  }

  .table-box {
    max-width: 1200px;
    overflow: auto;
    background: #ffffff;
    border: 1px solid #e7e7e7 !important;
    box-shadow: none;
    border-radius: 6px;
    margin-top: -20px;

    table.applications {
      background: #ffffff;
      width: 100%;
      color: rgb(102, 102, 102) !important;

      .three-dots-menu {
        position: relative;
        margin-top: -18px;
      }

      .menu-icon {
        cursor: pointer;
      }

      .dot {
        border-radius: 50%;
        width: 3px;
        height: 3px;
        margin: 2px;
        background: #616161;
      }
      .menu {
        position: absolute;
        top: 24px;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
        padding: 10px;
        z-index: 1;
      }

      .menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu li {
        margin: 5px 0;
        line-height: 30px;
        cursor: pointer;
      }

      .menu li:hover {
        color: black;
        background: #d3eaff;
      }

      tr {
        border-bottom: 1px solid rgb(245, 245, 245);

        &:hover {
          background-color: white !important;
        }
        &:last-child {
          border: none !important;
        }
        .smallTd{
          @media screen and (max-width:728px) {
            min-width: 130px !important ;
          }
        }
        .smallTdProfile{
          @media screen and (max-width:728px) {
            min-width: 300px !important ;
          }
        }
        .smallTdDate{
          @media screen and (max-width:728px) {
            min-width: 100px !important ;
          }
        }
      }

      td,
      th {
        border-top: none !important;
      }

      th {
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        font-size: 15px !important;
        letter-spacing: 0.9px;
        border-bottom: none;
        color: white !important;
        background: #4b92c8;
        text-align: start;
        // padding-left: 45px;
        text-align: center;
        text-transform: none;
        
        .tableHead{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px !important;
          margin: 0px 5px;
        }
        .tableHeadMoreWidth{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px !important;
          margin: 0px 5px;
        }
      }

      td {
        padding: 3rem 1rem;
        font-size: 13px !important;

        &:last-child {
          border-right: none;
        }
      }

      tr:last-child {
        td {
          border: none !important;
        }
      }

      td {
        height: 60px;

        strong.status {
          padding: 1rem 3rem;
          // font-size: 1.3rem;
          border-radius: 15px;
        }

        .viewed {
          background: #d8ecff;
          border-radius: 15px;
          color: #4b92c8;
          font-weight: 500;
          padding-left: 24px;
          padding-right: 24px;
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 13px;
        }

        .view {
          cursor: pointer;
          color: #006bc2;
        }
      }
    }
  }
}

.TableHead {
  background-color: rgb(202, 202, 202);
  color: rgb(80, 80, 80);
}

.previewIcon {
  color: #3c94f8;
  margin-left: 20px;
  font-size: 13px;
  cursor: pointer;
  margin-top: 5px;
}

.docIcon {
  color: #3c94f8;
  font-size: 23px;
  cursor: pointer;
}

.AdminSearchBox {
  max-width: 1200px;
  overflow: auto;
  background-color: transparent;
  box-shadow: none;
  justify-content: end;
  justify-items: end;
  text-align: end;
  width: 100%;
  margin-right: 5px;
  margin-top: -10px;
}
.AdminSearchBox3 {
  max-width: 1200px;
  overflow: auto;
  background: transparent;
  box-shadow: none;
  justify-content: end;
  justify-items: end;
  text-align: end;
  width: 100%;
  margin-right: 5px;
  margin-top: 34px;
}
.AdminSearchBox2 {
  max-width: 1200px;
  overflow: auto;
  background: transparent;
  box-shadow: none;
  justify-content: start;
  justify-items: start;
  text-align: start;
  width: 100%;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
}
.AdminSearchBoxmain {
  justify-content: space-between;
  max-width: 1200px;
  overflow: auto;
  background: transparent;
  box-shadow: none;
  width: 100%;
  margin-right: 5px;
  margin-top: -10px;
  display: flex;
}
.AdminSearchBoxsubmain {
  max-width: 1200px;
  // overflow: auto;
  background-color: transparent;
  box-shadow: none;
  justify-content: start;
  justify-items: start;
  text-align: start;
  width: 100%;
  margin-right: 5px;
  margin-top: 5px;
  // display: flex;
}
.AdminSearchBox2-mobile {
  display: none;
}
.AdminSearchBox-mobile {
  display: none;
}
.AdminSearchBoxsubmain-mobile {
  display: none;
}

.AdminSearchInput {
  padding: 9px 10px;
  outline-color: #4b92c8;
  border-radius: 5px 0 0 5px;
  border: solid 1px rgb(179, 179, 179);
  min-width: 210px;
  font-size: 14px;
  @media screen and (max-width:500px) {
    min-width: 180px;
    
  }
}

.AdminSearchButton {
  padding: 8px 10px 9px 10px;
  background-color: #4b92c8;
  border-radius: 0 5px 5px 0;
  border: none;
  color: white;
  font-weight: 400;
  margin-left: -2px;
}



@media screen and (max-width: 768px) {
  .AdminSearchBoxmain {
    justify-content: center;
    justify-items: center;
    text-align: center;
    // flex-direction: column;
    margin-right: 0px;
  }
  .AdminSearchBox {
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-right: 0px;
  }
  .AdminSearchBox3 {
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-right: 0px;
    margin-top: 34px;
  }
  .AdminSearchBox2 {
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-right: 0px;
  }
  .AdminSearchBoxsubmain {
    justify-content: flex-start;
    justify-items: flex-start;
    text-align: center;
    width: 100%;
    margin-right: 0px;
  }
}

@media screen and (max-width: 950px) {
  .AdminSearchBox {
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-right: 0px;
  }
  .AdminSearchBox3 {
    display: flex;
    justify-content: flex-end;
    justify-items: flex-end;
    text-align: center;
    // background-color: red;
    margin-right: 0px;
    margin-top: 30px;
  }
  .AdminSearchBox2 {
    justify-content: flex-start;
    justify-items: flex-start;
    text-align: center;
    margin-right: 0px;
  }
  .AdminSearchBoxsubmain {
    justify-content: flex-start;
    justify-items: flex-start;
    text-align: center;
    margin-right: 0px;
  }
}
@media screen and (max-width: 600px) {
  .AdminSearchBoxmain {
    flex-direction: column;
  }
  .AdminSearchBox2 {
    justify-content: center;
    justify-items: center;
  }
  .AdminSearchBoxsubmain {
    justify-content: center;
    justify-items: center;
  }
  .AdminSearchBox3 {
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    margin-right: 0px;
    margin-top: 25px;
  }

}
@media screen and (max-width: 425px) {
  .AdminSearchBox2-mobile {
    max-width: 1200px;
    overflow: auto;
    background: #ffffff;
    box-shadow: none;
    justify-content: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    margin-right: 5px;
    margin-top: 5px;
    display: flex;
  }
  .AdminSearchBox-mobile {
    max-width: 1200px;
    overflow: auto;
    background: #ffffff;
    box-shadow: none;
    justify-content: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    margin-right: 5px;
    // margin-top: -10px;
    margin-top: 5px;
    display: flex;
  }
  .AdminSearchBox3 {
    display: none;
  }
  .AdminSearchBox2 {
    display: none;
  }
  .AdminSearchBoxmain {
    display: none;
  }
  .AdminSearchBoxsubmain-mobile {
    max-width: 1200px;
    // overflow: auto;
    box-shadow: none;
    justify-content: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    margin-right: 5px;
    margin-top: 5px;
    display: block;
  }
  .AdminSearchBoxsubmain-mobile h3 {
    text-align: center;
  }
}

@media screen and (max-width: 317px) {
  .AdminSearchInput {
    min-width: 0;
  }
}

.borderremove123 {
  /* WebKit scrollbar styles */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(234, 247, 255); /* For Firefox */
  border-radius: 7px;

  /* WebKit (Chrome, Safari) scrollbar styles */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4b92c8;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eaf7ff; /* Light blue background */
    border-radius: 7px; /* Border radius of 7px */
  }
}

.NoApplicationButtons {
  background-color: #4b92c8;
  color: white;
  padding: 10px 20px;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-right: 30px;
  min-width: 250px;
}
.NoApplicationButtons:hover {
  background-color: #397eb3;
}

.NoApplicationButtons2 {
  background-color: #4b92c8;
  color: white;
  padding: 10px 20px;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  margin-bottom: 10px;
  min-width: 250px;
}
.NoApplicationButtons2:hover {
  background-color: #397eb3;
}

@media screen and (max-width: 566px) {
  .NoApplicationButtons {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .usertableheadingstyle {
    min-width: 200px !important;
  }
}
