

.reviewpage {
    background-color: rgba(224, 220, 243, 0.212);
    .r1 {
        .section1 {
            background: #FFFFFF;
            box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
            -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
            0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
            0 -3px 8px -3px rgba(233, 233, 250, 0.808);
            border-radius: 15px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 15px;
            padding-bottom: 15px;

            .title {
                .icon {
                    font-size: 14px;

                    i {
                        color: #0077E4;
                    }
                }

                .btntouch {
                    background: #c8f7cd;
                    border-radius: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #235510;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

              
            }

            .companyname {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 19px;
               
            }
        }

        .section3 {
            box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
            -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
            0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
            0 -3px 8px -3px rgba(233, 233, 250, 0.808);
            background: #FFFFFF;
            border-radius: 15px;
            border: "none";
       

            .reviewpagebtn {
                .btnrevie {
                    background: #FFFFFF;
                    border: 1px solid #C1C1C1;
                    border-radius: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .information {
                .Internship {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;

                    .internname {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: #3D3D3D;
                    }
                }

                .Education {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;
                }

                .titlee {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #6D6D6D;
                }

                .eduname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3D3D3D;
                }

                .clgname,
                .schlname,
                .year,
                .board,
                .percantage {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #5F5F5F;
                }
            }

            .heading {
                background: #D3EAFF;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;

                .aplied {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .cover {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #3D3D3D;
            }

            .hire {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #3D3D3D;
            }

            .para {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #5F5F5F;

            }
        }
    }
}


.inputApp{

    width: 100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border-radius: 10px;
    border-color: #5F5F5F;
    margin-top: 10px;
}