@import "../../../_variables";

.my-applications {
    // background-color: rgba(251, 251, 251, 0.1);
    background-color: rgba(224, 220, 243, 0.212) ;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    padding: 6rem 1rem;
    min-height: 60vh;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 48px;
        color: #000000;
    }

    .table-box {
        box-shadow: 3px 0 8px -3px rgba(233, 233, 250, 0.808),   /* Right */
        -3px 0 8px -3px rgba(233, 233, 250, 0.808),  /* Left */
        0 3px 8px -3px rgba(233, 233, 250, 0.808),  /* Bottom */
        0 -3px 8px -3px rgba(233, 233, 250, 0.808);
        max-width: 1000px;
  min-height: 0px !important;
        overflow: auto;
        background: #FFFFFF;
        // border: 1px solid #B4B4B4;
        border: none !important;
        border-radius: 18px;
        margin-top: -20px;
 
        table.applications {
            background: #FFFFFF;
            width: 100%;
             
            .three-dots-menu {
                position: relative;
                margin-top: -18px;
                
              }
              
              .menu-icon {
                cursor: pointer;
              }
              
              .dot {
                border-radius: 50%;
                width: 3px;
                height: 3px;
                margin: 2px;
                background: #616161;
              }
              .menu {
                position: absolute;
                top: 24px;
                right: 0;
                background-color: #fff;
                border: 1px solid #ccc;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                padding: 10px;
                z-index: 1;
              
              }
              
              .menu ul {
                list-style: none;
                padding: 0;
                margin: 0;
              }
              
              .menu li {
                margin: 5px 0;
                line-height: 30px;
                cursor: pointer;
              }
              
              .menu li:hover {
                color: black;
                background: #D3EAFF;              }

            tr {
                border-bottom: 1px solid #B4B4B4;

                &:hover {
                    background-color: rgb(249, 248, 248) !important;
                }
                &:last-child {
                    border: none !important;
                }
            }

            td,
            th {
                padding: 2rem 1rem;
                font-size: 1.2rem;
                border-top: none !important;
            }
            

            th {

                // padding: 2.9rem;
                font-style: normal;
                font-weight: 600;
                font-size: 12.5px !important;
                line-height: 15px;
                text-transform: uppercase;
                color: #000000 !important;
                border-bottom: none;
                width: 200px !important;
            }

            th {
                background: #D3EAFF;
            }

            td {
                padding: 3rem;

                &:last-child {
                    border-right: none;
                }
            }

            tr:last-child {
                td {
                    border: none !important;
                }
            }

            td {
                height: 60px;

                strong.status {
                    padding: 1rem 3rem;
                    // font-size: 1.3rem;
                    border-radius: 15px;
                }

                .viewed {
                    background: #D8ECFF;
                    border-radius: 15px;
                    color: #4B92C8;
                    font-weight: 500;
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-size: 13px;
                }

                .view {
                    cursor: pointer;
                    color: #006BC2;
                }
            }
        }
    }
}