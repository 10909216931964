$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-black: #000000;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.contract-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .contract-hero-section {
        display: flex;
        gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            h1 {
                color: $primary-blue;
                font-size: 45px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }
            .opportunities {
                margin-top: 1rem;
            }
            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                font-weight: 500;
                font-size: 1.5rem;
            }
            .signup-link {
                text-decoration: underline;
                color: $primary-blue;
            }
        }

        .hero-image {
            flex: 1;
            img {
                width: 100%;
                height: auto;
                border-radius: 0.5rem;
            }
        }
    }

    .how-it-works {
        h2 {
            font-size: 3rem;
            font-weight: 600;
            color: $text-black;
            @media (max-width: 1024px) {
                font-size: 2.5rem;
            }
            @media (max-width: 728px) {
                font-size: 2rem;
            }
        }
        
        margin-bottom: 4rem;

        &__content {
            display: flex;
            gap: 3rem;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        &__image {
            width: 40%;
            img {
                width: 100%;
                height: max-content;
                border-radius: 8px;
            }
            @media (max-width: 768px) {
                // width: 100%;
                img {
                    display: none;
                }
            }
        }

        &__steps {
            width: 60%;
            h2{
                font-size: 35px;
                
            }
            p{
                font-size: 16px;
                font-weight: 500;
                color: #3d3d3d;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .steps {
            .step {
                display: flex;
                align-items: flex-start;
                margin-bottom: 1.5rem;
                justify-content: flex-start;
                &__number {
                    background: $text-black;
                    color: white;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1rem;
                    flex-shrink: 0;
                    margin-top: 0.4rem;
                    @media (max-width: 728px) {
                        margin-top: 0rem;
                    }
                }

                h3 {
                    margin: 0;
                    font-size: 2rem;
                    @media (max-width: 1024px) {
                        font-size: 1.8rem;
                    }
                    @media (max-width: 728px) {
                        font-size: 1.3rem;
                    }
                }
                p {
                    font-size: 1.5rem;
                    color: #646464;
                    a{
                        color: #1f57c3;
                    }
                }
            }
        }
    }

    .reasons {
        margin-bottom: 4rem;

        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4rem;
            align-items: flex-start;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 2rem;
            }
        }

        &__text {
            h2 {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 2rem;
            }
            @media (max-width: 1024px) {
                font-size: 1.8rem;
            }
            @media (max-width: 728px) {
                font-size: 1.3rem;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        &__image {
            img {
                width: 100%;
                height: auto;
                border-radius: 12px;
                object-fit: cover;
            }
        }

        .reason {
            h3 {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 0.75rem;
                color: #333;
            }

            p {
                font-size: 17px;
                color: #646464;
                font-weight: 500;
            }
        }
    }

    // Discover Section
    .discover {
        background-color: $primary-blue;
        border-radius: 16px;
        padding: 3rem;
        color: white;

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;
            width: 100%;
            @media (max-width: 768px) {
                text-align: center;
                flex-direction: column;
            }
        }

        &__icon {
            background-color: #A8FFEE;
            padding: 2rem;
            border-radius: 12px;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media (max-width: 768px) {
                width: 100%;
            }

            .search-icon {
                width: auto;
                height: 150px;
                color: white;
            }
        }

        &__text {
            width: 40%;
            @media (max-width: 768px) {
                width: 100%;
            }
            h2 {
                font-size: 35px;
                margin-bottom: 1.5rem;
                font-weight: 500;
                line-height: 1.2;
                @media (max-width: 768px) {
                    font-size: 2.5rem;
                }
            }
        }
    }

    // Button Styles
    .button {
        padding: 0.75rem 1.5rem;
        border-radius: 20px;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s;
        font-size: 1.5rem;

        &--success {
            background-color: #108A00;
            color: white;

            &:hover {
                background-color: darken(#108A00, 5%);
            }
        }
    }
}

.primary-btn {
    background-color: $primary-blue;
    color: white;
    padding: 0.8rem 3rem;
    border: none;
    border-radius: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($primary-blue, 10%);
    }
}
