body{
    margin-top:20px;
    background:#FAFAFA;
}
.order-card {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.bg-lavender {
    background: linear-gradient(45deg,#a08af0,#977aff);
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.custom-dropdown-menu {
    position: absolute;
    transform: translate3d(0px, 32px, 0px);
    top: 0px; 
    left: 0px !important;
    will-change: transform;
    width: 100px !important;
}
/* .dropdown-menu.custom-dropdown-menu{
    position: absolute;
    transform: none !important;
    top: 100% !important;
    left: -16px !important;
} */
.custom-dropdown-menu1 {
    position: absolute;
    transform: translate3d(0px, 32px, 0px);
    top: 0px;
    left: 0px !important;
    will-change: transform;
    width: 100px !important;
}
/* .dropdown-menu.custom-dropdown-menu1{
    position: absolute;
    transform: none !important;
    top: 100% !important;
    left: -7px !important;
} */
.custom-dropdown-menu2 {
    position: absolute;
    transform: translate3d(0px, 32px, 0px);
    top: 0px;
    left: 0px !important;
    will-change: transform;
    width: 100px !important;
}
/* .dropdown-menu.custom-dropdown-menu2{
    position: absolute;
    transform: none !important;
    top: 100% !important;
    left: -4px !important;
} */

.dropdown3{
    width: 101px !important;
}

.btn.dropdown-toggle{
    width: 100px !important;
}


.sub-section123 {
    padding: 20px 30px 30px 30px;
    background-color: white;
    border: 1px solid #DDD;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    font-size: 14px;

    .details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        .title {
            font-size: 14px;
            color: #333;
            font-weight: 500;
            font-family: 'Inter var', sans-serif;
        }

        input,
        textarea {
            padding: 8px 10px;
            background-color: #F8F8F8;
            border: 1px solid #ddd;
            outline: none;
        }

        input:focus,
        textarea:focus {
            border: 1px solid #00a5ec;
        }
    }



}

.submit-btn123 {
    background-color: #00a5ec;
    border: 1px solid #00a5ec;
    outline: none;
    transition: 0.3s;
    color: white;
    padding: 3px 18px;
    border-radius: 2px;
    width: fit-content;
    margin-top: 20px;
    margin-left: auto;
}

.submit-btn123:hover {
    background-color: #048cc7;

}

.submit-btn123:active {
    background-color: black;

}

.job-deadline-set{
    position:relative;
    left:25.4%;
}

@media screen and (max-width: 1440px){
    .job-deadline-set{
        left:33.8%;
    }
}

@media screen and (max-width: 1024px){
    .job-deadline-set{
        left:38%;
    }
}
@media screen and (max-width: 768px){
    .job-deadline-set{
        left:0%;
    }
}


.editableconnectcredit{
    /* text-decoration: underline; */
    color: #4B92C8;
}
@media only screen and (min-width: 771px) {

    .tableheadingstyle{
        padding-left: 0px !important;
        /* padding-right: 10px !important; */
        padding-right: 0px !important;
    }
    .tableheadingstyle15{
        padding-left: 23px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle2{
        padding-left: 0px !important;
        padding-right: 15px !important;
    }
    
    .tableheadingstyle10{
        /* padding-left: 0px !important; */
        /* padding-left: 36px !important; */
        padding-right: 0px !important;
    }
    .tableheadingstyle1{
        /* padding-left: 16px !important; */
        padding-left: 27px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle3{
        padding-left: 0px !important;
        padding-right: 10px !important;
    }
    .tableheadingstyle9{
        /* padding-left: 0px !important; */
        padding-left: 35px !important;
        padding-right: 0px !important;
        text-transform: capitalize;
    }
    
    .tableheadingstyle6{
        padding-left: 7px !important;
    }
    .tableheadingstyle11{
        padding-left: 0px !important;
    }
    .tableheadingstyle23{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle12{
        padding-left: 0px !important;
    }
    .tableheadingstyle17{
        padding-left: 0px !important;
        padding-right: 8px !important;
    }
    
    .tableheadingstyle18{
        padding-left: 8px !important;
        padding-right: 4px !important;
    }
    
    .tableheadingstyle25{
        padding-left:10px !important;
        padding-right: 6px !important;
    }
    
    .tableheadingstyle26{
        padding-left: 5px !important;
        padding-right: 15px !important;
    }
    
    .tableheadingstyle21{
        padding-left: 21px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle22{
        padding-left: 30px !important;
        padding-right: 0px !important;
    }
    
    .tableheadingstyle24{
        padding-left: 0px !important;
        padding-right: 15px !important;
    }
}


@media only screen and (max-width: 767px) {
    .tableheadingstyle{
        padding-left: 15px !important;
        /* padding-right: 10px !important; */
        padding-right: 25px !important;
    }
    .tableheadingstyle15{
        padding-left: 23px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle2{
        padding-left: 0px !important;
        padding-right: 15px !important;
    }
    
    .tableheadingstyle10{
        /* padding-left: 0px !important; */
        /* padding-left: 36px !important; */
        padding-right: 0px !important;
    }
    .tableheadingstyle1{
        /* padding-left: 16px !important; */
        padding-left: 27px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle3{
        padding-left: 0px !important;
        padding-right: 10px !important;
    }
    .tableheadingstyle9{
        /* padding-left: 0px !important; */
        padding-left: 35px !important;
        padding-right: 0px !important;
    }
    
    .tableheadingstyle6{
        padding-left: 7px !important;
    }
    .tableheadingstyle11{
        padding-left: 0px !important;
    }
    .tableheadingstyle23{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle12{
        padding-left: 0px !important;
    }
    .tableheadingstyle17{
        padding-left: 0px !important;
        padding-right: 8px !important;
    }
    
    .tableheadingstyle18{
        padding-left: 8px !important;
        padding-right: 4px !important;
    }
    
    .tableheadingstyle25{
        padding-left:10px !important;
        padding-right: 6px !important;
    }
    
    .tableheadingstyle26{
        padding-left: 5px !important;
        padding-right: 15px !important;
    }
    
    .tableheadingstyle21{
        padding-left: 21px !important;
        padding-right: 0px !important;
    }
    .tableheadingstyle22{
        padding-left: 30px !important;
        padding-right: 0px !important;
    }
    
    .tableheadingstyle24{
        padding-left: 0px !important;
        padding-right: 15px !important;
    }
}

.deletehovering :hover{
    color: red;
}