/* .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
} */

.button-prev {
  /* background-color: white; */
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.button-next {
  /* background-color: white; */
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
}

.page-item {
  margin: 0 3px;
  /* background-color: white; */
  color: green;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-item.active {
  background-color: #0e709e;
  color: red;
  pointer-events: none;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.page-item[style*="cursor: default"] {
  color: #ccc;
  pointer-events: none;
}
.dots {
  margin: 0 3px;
}
.not-active {
  cursor: pointer;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.not-active:hover {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: black;
}
