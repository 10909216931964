$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.howtofindwork-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

    .howtofindwork-hero-section {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        padding: 2rem;
        background-color: #1f56c321;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: #1f57c3;
                font-size: 45px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.5rem;
                font-weight: 500;
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }
    .howtofindwork-how-it-works {
        padding: 0px;
        .howtofindwork-title {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 30px;
            color: #333;
        }

        .steps {
            display: flex;
            flex-direction: column;
            //   gap: 40px;
        }

        .step {
            display: flex;
            align-items: flex-start;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }

        .step-icon {
            flex-shrink: 0;
            //   width: 64px;
            //   height: 64px;
            background: #98f0b3;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0rem 4rem;
            margin: 1rem 2rem 1rem 0rem;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .howtofindwork-step-content {
            flex: 1.2;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding-top: 3rem;
        }

        .step-title {
            font-size: 23px;
            font-weight: 600;
            color: #000000;
            margin-bottom: 12px;
        }

        .step-description {
            font-size: 16px;
            color: #393939;
        }

        .create-profile-btn {
            background-color: #1f57c3;
            color: white;
            padding: 0.8rem 3rem;
            border: none;
            border-radius: 2rem;
            font-size: 1.5rem;
            margin-top: 2rem;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #1f57c3;
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.3);
            }
        }
    }

    // Responsive adjustments
    @media (max-width: 768px) {
        .howtofindwork-how-it-works {
            .howtofindwork-title {
                font-size: 30px;
                text-align: center;
            }
            .howtofindwork-step-content {
                align-items: center;
            }
            .steps {
                gap: 32px;
            }

            .step-icon {
                margin-bottom: 16px;
            }
        }
    }

    @media (max-width: 480px) {
        .howtofindwork-how-it-works {
            .howtofindwork-title {
                font-size: 30px;
                margin-bottom: 20px;
            }

            .step-title {
                font-size: 22px;
            }
            .howtofindwork-step-content {
                align-items: center;
            }
            .step-description {
                font-size: 18px;
            }
            .create-profile-btn {
                width: 100%;
            }
        }
    }

    .howtofindwork-hero-section-2 {
        display: flex;
        // gap: 2rem;
        border-radius: 1rem;
        margin-bottom: 3rem;
        margin-top: 5rem;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content-2 {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                font-size: 30px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 1.5rem;
                font-weight: 500;
            }
        }

        .hero-image-2 {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }

    .reasons {
        margin-bottom: 4rem;

        &__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4rem;
            align-items: flex-start;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 2rem;
            }
        }

        &__text {
            h2 {
                font-size: 35px;
                font-weight: 600;
                margin-bottom: 2rem;
            }
            p {
                font-size: 1.5rem;
                font-weight: 500;
                color: #606060;
            }
            @media (max-width: 1024px) {
                font-size: 1.8rem;
            }
            @media (max-width: 728px) {
                font-size: 1.3rem;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            a {
                color: #1f57c3;
            }
        }

        &__image {
            img {
                width: 100%;
                height: auto;
                border-radius: 12px;
                object-fit: cover;
            }
        }

        .reason {
            h3 {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 0.75rem;
                color: #333;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: #606060;
            }
        }
    }
}
