@import "../../../_variables";

.borderremove {
    box-shadow: 0px 0px 15px 0px #00000025;
}

.borderremoveMob {
    box-shadow: 0px 0px 15px 0px #00000025;
}

.borderremovePc {
    box-shadow: 0px 0px 15px 0px #00000025;
}

.post001 {
    background: #ffffff;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 12px;
    font-weight: bold;
    color: #4b92c8;
    border: solid 1px lightskyblue;
}

.matched-jobs {
    padding: 1rem;
    background-color: rgba(224, 220, 243, 0.212);

    .jobbox {
        .alljobpage {
            .btnsearch {
                background: #4b92c8;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
                color: #ffffff;
                border-radius: 5px;
                padding-left: 33px;
                padding-right: 33px;
                padding-top: 6px;
                padding-bottom: 6px;

                transition: background-color 0.3s ease;
                /* Add a transition for a smooth hover effect */

                &:hover {
                    background: #3571a9;
                    /* Change the background color on hover */
                }
            }
        }

        .alljobdetails {
            .titles {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #4f4f4f;
            }

            .cards {
                .card {
                    background: #ffffff;
                    border: 1px solid #ebebeb;
                    box-shadow: 0px 2px 5px rgba(221, 222, 250, 0.808);
                    border-radius: 5px;

                    .heeading {
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 17px;
                        color: #8c8c8c;
                    }

                    .head {
                        margin-top: 7px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 17px;
                        color: #4f4f4f;
                    }

                    .cardbutton {
                        .btnview {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 17px;
                            color: #0077e4;
                            background: #ffffff;
                            border: 1px solid #ebebeb;
                            border-radius: 5px;
                            padding-left: 15px;
                            padding-right: 15px;
                            transition: background-color 0.3s ease;
                            /* Add a transition for a smooth hover effect */

                            &:hover {
                                background: #e0e0e0;
                                /* Change the background color on hover */
                            }
                        }

                        .btnapply {
                            background: #4b92c8;
                            border-radius: 4px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 17px;
                            color: #ffffff;
                            padding-left: 15px;
                            padding-right: 15px;
                            transition: background-color 0.3s ease;
                            /* Add a transition for a smooth hover effect */

                            &:hover {
                                background: #3571a9;
                                /* Change the background color on hover */
                            }
                        }
                    }
                }

                .cname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #4f4f4f;
                }

                .sub {
                    .name {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 17px;
                        color: #8c8c8c;
                    }

                    .type {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #9e7357;
                        background: #fffaeb;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    .time {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #459e25;
                        background: #eaffd5;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    .worktype {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #de5aff;
                        background: #f9e3ff;
                        border-radius: 5px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            }
        }

        padding-top: 20px;
        padding-bottom: 30px;

        .filterbox {
            position: sticky;
            top: 100px; // Adjust this value based on your header's height
            // max-height: calc(100vh - 200px); // Adjust this value based on your header and footer heights
            overflow-y: auto;
            overflow-x: hidden;
            background: #ffffff;
            border: 1px solid #ebebeb;
            box-shadow: 0px 2px 5px rgba(221, 222, 250, 0.808);
            border-radius: 5px;
            padding: 15px;

            scrollbar-width: thin; /* For Firefox */
            scrollbar-color: rgb(39, 148, 236) rgb(234, 247, 255); /* For Firefox */

            /* WebKit (Chrome, Safari) scrollbar styles */
            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgb(39, 148, 236);
                border-radius: 40px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgb(234, 247, 255);
            }

            .filterboxoption {
                input[type="range"] {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 2px;
                    border-radius: 5px;
                    background-color: #4b92c8;
                    outline: none;
                }

                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: #4b92c8;
                    cursor: pointer;
                }

                input[type="range"]::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #4b92c8;
                    cursor: pointer;
                }

                input::placeholder {
                    font-size: 12px;
                }

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: #4f4f4f;
                }

                .form-check-label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #8c8c8c;
                }
            }
        }

        .input-box {
            position: relative;

            input {
                background: #ffffff;
                border: 1px solid #c7c7c7;
                border-radius: 5px 0px 0px 5px;
                padding: 1rem 3.4rem;
                width: 100%;
                outline: none;
            }

            svg {
                &:first-child {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 1.3rem 0.8rem;
                }
            }
        }
    }

    .title {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .filter-icon,
    .close_btn {
        display: none;
    }

    .jobs-box {
        display: flex;
        justify-content: center;
        gap: 5rem;
        padding-top: 4rem;

        .filters-box {
            background-color: #efefef;
            padding: 3rem;
            max-height: 600px;
            overflow: auto;

            .filter-title {
                display: flex;
                justify-content: center;
                gap: 1rem;
            }

            .filters {
                padding: 3rem 0;

                .filter {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding: 0.5rem 0;
                }
            }

            .input-box {
                padding: 1.5rem 0;

                label {
                    display: block;
                    padding-bottom: 0.5rem;
                    font-size: 1.5rem;
                }

                input {
                    width: 100%;
                    padding: 0.8rem 1rem;
                    outline: none;
                    border: 1px solid #ccc;
                }
            }
        }

        .jobs {
            .job-card {
                margin-bottom: 3rem;
                padding: 2rem;
                width: 550px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                box-shadow: 1px 2px 20px rgba(151, 151, 151, 0.2);

                .company-title {
                    padding: 1rem 0;
                    font-size: 1.8rem;
                }

                .job-title {
                    font-weight: 500;
                    color: #8a8a8a;
                    padding-bottom: 2.5rem;
                    font-size: 1.5rem;
                }

                .info-box {
                    display: flex;
                    gap: 3rem;
                    padding-bottom: 3rem;

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;

                        span {
                            font-size: 1.4rem;
                        }
                    }
                }

                .options {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 2rem;
                    padding-top: 1.5rem;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);

                    * {
                        color: $PRIMARY_COLOR;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .matched-jobs {
        .title {
            position: relative;
        }

        .filter-icon {
            display: block;
            position: absolute;
            right: 0;
            top: -4rem;
            cursor: pointer;
        }

        .close_btn {
            display: block;
            position: absolute;
            top: 1rem;
            right: 2rem;
            cursor: pointer;
        }

        .jobs-box {
            .filters-box {
                position: fixed;
                width: 95%;
                height: 90vh;
                display: none;
                top: 0;
                left: 0;
                min-height: 95vh;
                margin: 2rem;

                &.open {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1058px) {
    .borderremoveMob {
        display: none;
    }
}

@media screen and (max-width: 1057px) {
    .borderremovePc {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .matched-jobs {
        .jobs-box {
            .jobs {
                width: 100%;

                .job-card {
                    width: 100%;
                }
            }
        }
    }

    .btnview {
        margin-bottom: 10px;
    }

    .worktype {
        margin-top: 10px;
    }
}

/* Pagination Styles */

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        padding: 8px 12px;
        margin: 0 4px;
        background-color: #4b92c8;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #3571a9;
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    span {
        margin: 0 10px;
        font-weight: bold;
        font-size: 14px;
        color: #333;
    }
}

// .fixed-header {
//     position: sticky;
//     top: 60px;
//     left: 547px;
//     right: 107.5px;
//     background-color: rgb(255, 255, 255);
//     z-index: 1000;
//     padding: 20px;
//     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
//     border-radius: 5px;
//     max-height: calc(100vh - 200px);
//     overflow-y: auto;
//     overflow-x: hidden
// }

// .content-below-header {
//     margin-top: 100px;
// }

.apply-margin {
    margin-left: 140px;
}

.chatIconOfemp {
    color: rgb(148, 148, 148);
}

.chatIconOfemp:hover {
    color: rgb(6, 61, 180);
}

/* For Webkit browsers */
.borderremove {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #4b92c8 rgb(234, 247, 255); /* For Firefox */

    /* WebKit (Chrome, Safari) scrollbar styles */
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #4b92c8;
        border-radius: 40px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgb(234, 247, 255);
    }
}

.show-connect-button-employer {
    background: #4b92c8;
    border-radius: 5px;
    color: white;
    outline: none;
    border: none;
    padding: 5px;
    box-shadow: 1px 2px 20px rgba(151, 151, 151, 0.2);
    margin-left: 5px;
    font-size: 11px;
}
