

.reviewpage {
    background-color: white;
    .r1 {
        .section1 {

            box-shadow: 2px 0 5px -2px rgba(245, 245, 245, 0.808),   /* Right */
            -2px 0 5px -2px rgba(245, 245, 245, 0.808),  /* Left */
            0 2px 5px -2px rgba(245, 245, 245, 0.808),  /* Bottom */
            0 -2px 5px -2px rgba(245, 245, 245, 0.808);
            background: #FFFFFF;
            border: 1px solid rgb(233, 233, 233);
            border-radius: 6px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 20px;
            padding-bottom: 20px;
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width:500px) {
                padding-left: 15px;
                padding-right: 15px;
                
            }
            .title123 {

                font-size: 27px;
                letter-spacing: 0.5px;
                .icon {
                    font-size: 18px;
             

                    i {
                        color: #1d8ef8;
                 
                    }
                }

                .btntouch22 {
                    background: #e3f7ff;
                    border-radius: 15px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.5px;
                    color: #1177d6;
                    padding-left: 35px;
                    padding-right: 35px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: none;
                    cursor: default;
        
                }

              
            }

            .companyname22 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                color: rgb(78, 78, 78);
            }
        }

        .section3 {
            background: #FFFFFF;
            border: 1px solid rgb(241, 241, 241);
            border-radius: 6px;
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none;
       

            .reviewpagebtn {
                .btnrevie {
                    background: #FFFFFF;
                    border: 1px solid #C1C1C1;
                    border-radius: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .information {
                .Internship {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;

                    .internname {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: #3D3D3D;
                    }
                }

                .Education {
                    border-bottom: 1px solid #B6B6B6;
                    padding-bottom: 7px;
                }

                .titlee {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    text-transform: uppercase;
                    color: #6D6D6D;
                }

                .eduname {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3D3D3D;
                }

                .clgname,
                .schlname,
                .year,
                .board,
                .percantage {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #5F5F5F;
                }
            }

            .heading {
                background: #e8f4ff;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 20px;
                padding-bottom: 15px;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
             

                .aplied12 {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    color: rgb(82, 82, 82);
                    font-size: 18px;
                }

                .aplied212 {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    color: rgb(53, 139, 252);
                    margin-top: 8px;
                }
            }

            .cover {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #3D3D3D;
            }

            .hire {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #3D3D3D;
            }

            .para {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #5F5F5F;

            }
        }
    }
}


.inputApp{

    width: 100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border-radius: 10px;
    border-color: #5F5F5F;
    margin-top: 10px;
}


.dropdown-divider{
    color: rgb(185, 185, 185);
    margin-left: 15px;
    margin-right: 15px;
}

.btntouch223 {
    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
    margin-top: 20px;
    margin-right: 1rem;

}

.shortcut-newappliedjob {
    padding: 4px 16px;
    outline: none;
    transition: all 0.5s;
    font-weight: 500;
    border: 1px solid #00a5ec;
    background-color: white;
    color: #00a5ec;
    font-size: 16px;
    margin-left: 11px;

    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    // padding-left: 35px;
    // padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
}
.shortcut-newappliedjob1 {
    padding: 4px 16px;
    outline: none;
    transition: all 0.5s;
    font-weight: 500;
    border: 1px solid #00a5ec;
    background-color: white;
    color: #00a5ec;
    font-size: 16px;
    margin-top: 20px;

    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    // padding-left: 35px;
    // padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
}
.shortcut-newappliedjob3 {
    padding: 4px 16px;
    outline: none;
    transition: all 0.5s;
    font-weight: 500;
    border: 1px solid #00a5ec;
    background-color: white;
    color: #00a5ec;
    font-size: 16px;
    margin-top: 20px;

    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    // padding-left: 35px;
    // padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
}
.shortcut-newappliedjob4 {
    padding: 4px 16px;
    outline: none;
    transition: all 0.5s;
    font-weight: 500;
    border: 1px solid #00a5ec;
    background-color: white;
    color: #00a5ec;
    font-size: 16px;
    margin-top: 20px;
    width: fit-content;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 10px;

    background: #e3f7ff;
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1177d6;
    // padding-left: 35px;
    // padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    cursor: default;
}



@media screen and (min-width: 956px) {
.btntouch223{
    display: none;
}
.mobTit{
    display: none;
}
.shortcut-newappliedjob1{
    display: none;
}
.shortcut-newappliedjob4{
    display: none;
}
}
.leftsectionpreviewport{
    top: 0px;
    background-color: white;
    @media screen and (max-width:1024px) {
        top: -10px;
        width: 100%;
    }
}


@media screen and (max-width: 955px) {
    .btntouch22{
        display: none;
    }
    // .shortcut-newappliedjob{
    //     display: none;
    // }
    .shortcut-newappliedjob3{
        display: none;
    } 
   
}



@media screen and (max-width: 470px) {
    .pcTittitle{
        display: none;
    } 
   
}