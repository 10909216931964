$primary-blue: #1f57c3;
$secondary-blue: #1d4ed8;
$text-dark: #262626;
$text-gray: #6b7280;
$background-light: #ffffff;
* {
    font-family: "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}
.marketplace-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    margin-top: 13rem;
    .marketplace-hero-section {
        display: flex;
        background-color: white;
        border-radius: 1rem;
        margin-bottom: 3rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .hero-content {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            h1 {
                color: #000000;
                font-size: 60px;
                margin-bottom: 1rem;
                font-weight: bold;

                @media (max-width: 1024px) {
                    font-size: 3rem;
                }
                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1.5rem;
                line-height: 1.6;
                font-size: 2rem;
                font-weight: 500;
            }
        }

        .hero-image {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 90%;
                height: auto;
                border-radius: 0.5rem;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            @media (max-width: 768px) {
                margin-top: 1rem;
            }
        }
    }
    

   

    .job-post-section {
        display: flex;
        gap: 3rem;
        margin: 3rem 0;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .job-post-image {
            flex: 1;
            img {
                width: 100%;
                height: 80%;
                object-fit: cover;
                border-radius: 1rem;
            }
        }

        .job-post-content {
            flex: 1;

            h2 {
                font-size: 40px;
                color: #000000;
                margin-bottom: 1rem;
                font-weight: 700;
                margin-top: 2rem;
            }

            h3 {
                font-size: 2.2rem;
                color: #3c3c3c;
                margin-bottom: 1rem;
                @media (max-width: 1024px) {
                    font-size: 1.8rem;
                }
            }

            p {
                color: #7a7a7a;
                margin-bottom: 1rem;
                line-height: 1.6;
                font-size: 1.5rem;
                font-weight: 500;
            }
            .payment{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 1rem;
                margin-top: 2rem;
                img{

                }
                .payment-content h3{
                    font-size: 22px;
                }
                .payment-content p{
                    color: #000000;
                    font-size: 1.3rem;

                }
            }
        }
    }
    .primary-btn {
        background-color: $primary-blue;
        color: white;
        padding: 0.8rem 14rem;
        border: none;
        border-radius: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
        
        &:hover {
            background-color: darken($primary-blue, 10%);
        }
        @media (max-width: 1024px) {
            padding: 0.8rem 7rem;
        }
        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }
    
    .post-btn {
        background-color: $primary-blue;
        color: white;
        padding: 0.8rem 5rem;
        border: none;
        border-radius: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        margin-top: 2rem;
        transition: background-color 0.3s ease;
        
        &:hover {
            background-color: darken($primary-blue, 10%);
        }
        // @media (max-width: 1024px) {
        //     padding: 0.8rem 7rem;
        // }
        // @media (max-width: 768px) {
        //     font-size: 2rem;
        // }
    }
    
    .signup-text {
        margin-top: 1rem;
        font-size: 0.9rem;
    
        .signup-link {
            color: $primary-blue;
            text-decoration: none;
    
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    .marketPlace-benefits-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      
        h2 {
          text-align: center;
          font-size: 40px;
          color: #333;
          margin-bottom: 3rem;
          font-weight: bold;
        }
      
        .benefits-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          width: 100%;
          
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
      
          .benefit-card {
            padding: 1.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2rem;
            @media (max-width: 500px) {
                flex-direction: column;
            }

            .benefit-header {
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 1.5rem;
      
              .benefit-icon {
                font-size: 1.5rem;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f8f9fa;
                border-radius: 50%;
              }
      
              h3 {
                font-size: 2.5rem;
                color: #333;
                font-weight: 600;
                margin: 0;
              }
            }
      
            .benefit-features {
              list-style: none;
              padding: 0;
              margin: 0;
      
              li {
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
                color: #000000;
                font-size: 1.4rem;
                line-height: 1.6;
                margin-bottom: 0.75rem;
      
                .bullet {
                  color: #000000;
                  margin-top: 0.2rem;
                }
              }
            }
          }
        }
      }
      
      // Additional responsive styling
      @media (max-width: 480px) {
        .benefits-container {
          padding: 2rem 1rem;
      
          h2 {
            font-size: 2rem;
            margin-bottom: 2rem;
          }
      
          .benefits-grid {
            gap: 1.5rem;
      
            .benefit-card {
              padding: 1rem;
            }
          }
        }
      }
}

.category-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #F6F6F6;
    .categories-container {
        padding: 2rem;
        width: 1200px;
        .categories-header {
          text-align: left;
          margin-bottom: 2.5rem;
      
          h2 {
            font-size: 3rem;
            color: #333;
            margin-bottom: 1rem;
            font-weight: bold;
          }
      
          p {
            color: #7a7a7a;
            font-size: 1.5rem;
            line-height: 1.6;
            max-width: 800px;
            font-weight: 500;
          }
        }
      
        .categories-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2.5rem;
          
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
      
          .category-card {
            display: flex;
            align-items: center;
            padding: 2rem 1.5rem;
            background: white;
            border-radius: 1rem;
            box-shadow: 0 2px 5px rgba(0, 0, 5, 0.192);
            transition: transform 0.2s ease, box-shadow 0.2s ease;
            cursor: pointer;
      
            
            .category-icon {
              font-size: 1.8rem;
              margin-right: 1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 50px;
                height: 50px;
                object-fit: cover;
              }
            }
      
            .category-content {
              flex: 1;
      
              h3 {
                font-size: 2rem;
                color: #333;
                margin-bottom: 0.5rem;
                font-weight: 600;
              }
      
              p {
                color: #666;
                font-size: 1.5rem;
                margin: 0;
              }
            }
          }
        }
      }
}
