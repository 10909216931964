@import "../../../_variables";

.app-navbar {
  display: flex;
  justify-content: space-between;
  padding: 2rem 4rem;
  align-items: center;
  border-bottom: 1px solid #33333365;
  // box-shadow: 1px 2px 2px #33333364;
  height: 95px;

  .login-outline {
    border-radius: 4.23851px;
    border: 1px solid #4b92c8;
    color: #4b92c8;
    font-weight: 600;
    font-size: 12px;
  }
  .registration-btn {
    background-color: #4b92c8;
    color: white;
    font-weight: 600;
    font-size: 12px;
  }
  .logo-box {
    img {
      height: 50px;
      width: auto;
    }
    &:hover .logo-box {
      display: block;
    }
  }

  .links-box {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    .links {
      font-family: "Roboto", sans-serif;
    }

    .links {
      text-decoration: none;
      letter-spacing: 0.25px;
      font-weight: 500;
    }

    .user-links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      a,
      button {
        padding: 0.6rem 1.8rem;
        // width: 90px;
        position: relative;

        &:hover .dropdown-box {
          display: block;
        }

        .dropdown-box {
          position: absolute;
          top: 100%;
          right: -35%;
          display: none;
        }

        .dropdown {
          background-color: white;
          display: flex;
          flex-direction: column;
          width: 150px;
          box-shadow: 1px 2px 2px #3333334d;
          border: 1px solid #3333334d;
          padding: 1rem;
          margin-top: 1rem;

          a {
            padding: 0.8rem 1.2rem;
            font-weight: 500;

            &:hover {
              background-color: #0077e41f;
              // color: white;
              text-decoration: none;
            }
          }
        }
      }

      .my-profile {
        position: relative;

        .profile {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          cursor: pointer;

          &:hover .profile-links {
            display: block;
          }
        }

        .user-photo {
          display: block;
          width: 30px;
          height: 30px;
          border: 1px solid rgba(0, 0, 0, 0.6);
          border-radius: 50%;
        }

        .profile-links {
          position: absolute;
          top: 100%;
          right: -30px;
          display: none;
          z-index: 1;
          padding-top: 2rem;

          .links {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            flex-direction: column;
            box-shadow: 1px 2px 8px rgba(41, 40, 40, 0.4);
            padding-top: 2rem;
            display: flex;
            width: 180px;

            a,
            button {
              padding: 1rem 2rem;
              font-weight: 400;
              font-size: 1.4rem;
              display: flex;
              align-items: center;
              gap: 1rem;
              background-color: white;
              border: none;

              &:hover {
                text-decoration: none;
                background-color: #ccc;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .app-navbar {
    padding: 2rem 8rem;
  }
}

@media screen and (min-width: 620px) {
  .app-navbar {
    .links-box {
      display: flex;
    }
  }
}
