.popular-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.popular-list span {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #484848;
}

.popular-list ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.popular-list ul li div {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.popular-list ul div {
  font-size: 14px;
  line-height: 1.14285714;
  font-weight: 500;
  color: #484848;
  padding: 7px 15px;
  border: 1px solid #ddd;
  border-radius: 99px;
  display: block;
  cursor: pointer;
}

.popular-list ul li.active {
  pointer-events: none;
  background-color: #008bdc;
  border-color: transparent;
  border-radius: 99px;
  div {
    color: #fff;
  }
}

.diffTitle{
  text-align: center;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #484848;
}

@media screen and (max-width: 1052px) {
  .popular-list{
    display:block;
  }

  .popular-list span {
   display: none;
  }
}


@media screen and (min-width: 1052px) {
  .diffTitle{
   display: none;
  }
}