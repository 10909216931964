#my-applications {
  /* background-color: rgba(224, 220, 243, 0.212) !important; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

.three-dot-modal-container {
  border: 1px solid rgb(219, 220, 219);
}

.toggle-btn {
  background: transparent;
  padding: 2px 4px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 4px;
  font: black;
  font-weight: 500;
}

.toggle-btn-active {
  border: blue;
  font: blue;
}

.table-container {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;

}


table {
  background: white;
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
}

th {
  border-bottom: 1px solid #ddd;
  color: #484848;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #F8F8F8;
  padding: 20px 20px;

}

td {
  border-bottom: 1px solid #ddd;
  padding: 20px 32px;
  font-size: 14px;
  font-weight: 400px;
  color: #484848;
  line-height: 22px;
}

tbody tr {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}


.application-status {
  width: 164px;
  padding: 2px 12px;
  font-size: 14px;
  border: none;
  border-radius: 14px;
  cursor: default;
}

td .active,
.job-container .active {
  background-color: #f1ffe5;
  color: #13800d;
}

td .close,
.job-container .close {
  background-color: black;
  color: #484848;
}

.premium {
  background-image: url("../../../assets/svgs/premium.svg");
  align-items: center;
  width: 101px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 2px 10px 2px 12px;
  color: white;
  gap: 10px;
}

.premium-mobile {
  background-image: url("../../../assets/svgs/premium.svg");
  align-items: center;
  /* width: 101px; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 1px 10px 1px 12px;
  color: white;
  gap: 10px;
}

.view .active {
  transition: 0.5s;
  background-color: #00a5ec;
  border-radius: 4px;
  border: 1px solid #00a5ec;
}

.view .active:hover {
  background-color: #0393d1;
}

.view .inactive {
  border-radius: 4px;
  background-color: #bbb;
}

.fa-ellipsis-vertical {
  transition: 0.2s;
  padding: 8px;
  border-radius: 100%;
}

.fa-ellipsis-vertical:hover {
  transition: 0.2s;
  padding: 8px;
  border-radius: 100%;
  background-color: #e7e1e1;
}

#dropdown-container {
  position: absolute;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  padding: 10px;
  width: 200px;
  top: 30px;
  right: 0px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.main-span {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Inter var", sans-serif;
}

.main-span a {
  color: #666;
  background-color: white;
  text-decoration: none;
  gap: 8px;
  display: flex;
  align-items: center;
}

.main-span:hover,
.main-span a:hover {
  color: #1979e0;
  background-color: white;
  text-decoration: none;
}

.pagination a {
  color: #00a5ec;
  font-size: 14px;
}
.paginationWrapper{
  max-width: 1200px;
  width: 100%;
  margin-inline:auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  
}
@media screen and (max-width:1268px) {
  .paginationWrapper{
    justify-content: center;
    align-items: center;
    
  }
}
.table-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.job-container {
  border: none;
  background-color: white;
  border-radius: 0px;
  padding: 16px 0px 24px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.job-container .profile {
  margin-right: 16px;
  color: #333;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.job-container .profile > :nth-child(2) {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
  font-weight: 500;
}

.job-container .share-link {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 32px;
  width: 79px;
  padding: 6px 12px 6px 6px;
  border-radius: 24px;
  border: 1px solid #eee;
  margin-left: auto;
  color: #484848;
}

.job-container div a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #008bdc;
  font-size: 14px;
  font-weight: 400;
}

.container-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.container-footer > :first-child > :not(:first-child) {
  padding-left: 12px;
}

@media screen and (max-width: 766px) {
  .view {
    width: 25%;
  }

  .premium-mobile {
    background: linear-gradient(44.53deg, #00489a 0, #008bdc 94.54%);
    border-radius: 3px;
    padding: 2px 6px;
    width: 63px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .premium-mobile > :first-child {
    display: none;
  }
}

@media screen and (min-width: 850px) {

  .table-container-mobile {
    width: 800px;
    max-width: 800px;
    background-color: #f8f8f8;

  }

  .job-container {
    border: 1px solid #eee;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 24px 24px 16px;
    width: 100%;
  }

  .job-container div a {
    font-size: 16px;
  }

  .container-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
  }

  .container-footer > :first-child > :not(:first-child) {
    padding-left: 24px;
    /* Example style */
  }
}
/* @media screen and (min-width: 850px) {
  .table-container-mobile {
    width: 100%;
    max-width: 100%;
  }
  .job-container{
    width: 100%;
  }

} */
@media screen and (min-width: 1000px) {
  .table-container-mobile {
    width: 800px;
    max-width: 800px;
    background-color: #f8f8f8;
  }
  .job-container{
    width: 100%;
  }

}
.table-container {
  display: flex;
}
@media screen and (min-width: 1255px) {
  #my-applications {
    background-color: white;
  }


  .table-container-mobile {
    display: none !important;
  }
}