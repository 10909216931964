.ChatMobView {
    @media screen and (max-width: 1000px) {
        margin-bottom: 40px;
    }
    .messageTitle {
        font-size: 28px;
        font-weight: 500;
        @media screen and (max-width: 1200px) {
            font-size: 25px;
            margin: 0px 2rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 23px;
            margin: 0px 2rem;
        }
    }
}
.message-student {
    z-index: 10000;
    .chat-box1 {
        // width: 96%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 89vh;
        // max-height: 700px;
        background: #ffffff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
        border-radius: 6px;

        .chatSlider {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
            display: flex;
            border-top-left-radius: 6px;
            // border-bottom-left-radius: 6px;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #f0eeee;
            .messageTitle {
                font-size: 28px;
                font-weight: 500;
                @media screen and (max-width: 1200px) {
                    font-size: 25px;
                    margin: 0px 1rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 23px;
                    margin: 0px 1rem;
                }
            }
            .sliderSearch,
            .chatSliderDropdown {
                width: 90%;
                margin: 0 16px;
                border: 1px solid #ddd;
                border-radius: 3px;
                padding: 7px 11px;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                position: relative;
                cursor: text;
                background-color: #fff;
            }

            .chatSliderDrowpdownMenu {
                width: 100%;
                border-radius: 10px;
                border: none;
                // padding: 10px;
                // font-size: 14px;
                // font-weight: 400;
                // background-color: rgb(234, 247, 255);
                // max-height: 250px;
                // overflow: auto;

                /* WebKit scrollbar styles */
                scrollbar-width: thin; /* For Firefox */
                scrollbar-color: rgb(234, 247, 255); /* For Firefox */

                /* WebKit (Chrome, Safari) scrollbar styles */
                &::-webkit-scrollbar {
                    width: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #dddddd;
                    border-radius: 16px;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgb(234, 247, 255);
                }
            }

            .btn.chatSliderDropdown.focus-visible,
            .btn.chatSliderDropdown:focus {
                outline: none;
                box-shadow: none; /* Optional: Remove box-shadow as well */
            }

            .chatSliderDropdownSelect {
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 500;
                margin-top: 5px;
                margin-bottom: 5px;
                cursor: pointer;
            }

            .chatSliderDropdownSelect:hover {
                background-color: rgb(30, 120, 223);
                color: white;
                font-weight: 300;
                border-radius: 8px;
            }

            .chatSliderBtn {
                border: none;
                width: 100%;
                border-radius: 10px;
                padding-top: 7px;
                padding-bottom: 5px;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 14px;
                font-weight: 400;
                margin-top: 25px;
                background-color: white;
                color: rgb(151, 151, 151);
                text-align: start;
            }

            .chatSliderIcon {
                font-size: 16px;
                margin-right: 17px;
                color: rgb(160, 160, 160);
                margin-top: -2px;
            }
        }
        .chatSliderDropdownRow:hover {
            transition: "background 0.3s ease";
            background: #eafcff;
        }
        .chatSliderDropdownRow:hover .chatSliderDropdownItem {
            color: #008bdc;
        }

        .chatSliderDropdownItem {
            font-size: 12px;
            color: #333;
            font-weight: 500;
        }

        .chatSliderDropdownItem:hover {
            color: #0077e4;
        }

        .recent-chats {
            display: flex;
            flex-direction: column;
            width: 25%;
            .messageTitle {
                font-size: 28px;
                font-weight: 500;
                margin: 0px 2rem;
                @media screen and (max-width: 1200px) {
                    font-size: 25px;
                    margin: 0px 2rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 23px;
                    margin: 0px 2rem;
                }
            }
            ::-webkit-scrollbar {
                width: 8px;
            }

            ::-webkit-scrollbar-thumb {
                background: #dddddd;
                border-radius: 16px;
            }

            .tabss {
                height: 68px;
                border-bottom: 1px solid #cbcbcb;
                background: #ffffff;
                gap: 2rem;
            }

            .btn3 {
                background: #4b92c8;
                border-radius: 20px;
                color: white;
                padding-left: 20px;
                padding-right: 20px;
                margin-left: 20px;
                font-size: 14px;
            }

            .btn4 {
                background: #ffffff;
                border: 1px solid #dbdbdb;
                border-radius: 30px;
                padding-left: 35px;
                padding-right: 35px;
                position: relative;
                font-size: 14px;
                margin-left: -40px;
            }

            .chats {
                background: #ffffff;
                height: 100%;
                overflow: auto;
                border-right: 1px solid #f0eeee;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
                margin-top: 3px;
                .wholeBox {
                    // padding: 0 15px;
                    .roundedNameProfile {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        margin-right: 1rem;
                        @media screen and (max-width: 1200px) {
                            width: 40px;
                            height: 40px;
                        }
                        @media screen and (max-width: 768px) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .roundedNameProfileLetter {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        border: 2px solid gray;
                        margin-right: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 2rem;
                        font-weight: 600;
                        @media screen and (max-width: 1200px) {
                            width: 40px;
                            height: 40px;
                            font-size: 17px;
                            font-weight: 500;
                        }
                        @media screen and (max-width: 768px) {
                            width: 30px;
                            height: 30px;
                            font-size: 14px;
                        }
                    }
                    .round {
                        // width: 100%;
                        max-width: 100px;
                        margin-right: 1rem;
                        .content {
                            width: 50px !important;
                            height: 50px !important;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid gray;
                        }
                    }
                }

                .chat {
                    padding: 8px 15px;
                    // border-radius: 15px;
                    position: relative;
                    // transition: 0.3s ease-out;
                    overflow: hidden;
                    transition: all 0.2s ease-in-out;
                    display: block;
                    text-decoration: none;
                    cursor: pointer;
                    margin: 0px 6px;
                    .date {
                        position: absolute;
                        top: 1rem;
                        right: 1.5rem;
                        font-size: 1rem;
                        font-weight: 500;
                    }

                    .status {
                        background: #c8e4ff;
                        border-radius: 8px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 17px;
                        padding-left: 25px;
                        padding-right: 25px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }
                .chat:hover::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 4px; /* Adjust the width as needed */
                    background-color: #008bdc; /* Add your desired blue color here */
                }
            }

            .chat-active {
                padding: 8px 15px;
                // border-radius: 15px;
                position: relative;
                // transition: 0.3s ease-out;
                display: block;
                text-decoration: none;
                overflow: hidden;
                cursor: pointer;
                margin: 0px 6px;
                border-radius: 5px;
                background-color: #e2e2e2;
                // color: white;
                // &::before {
                //   content: '';
                //   position: absolute;
                //   left: 0;
                //   background-color: #008bdc; /* Add your desired blue color here */
                //   top: 0;
                //   height: 100%;
                //   width: 4px; /* Adjust the width as needed */
                // }

                .date {
                    position: absolute;
                    top: 1rem;
                    right: 1.5rem;
                    font-size: 1rem;
                    font-weight: 500;
                }

                .status {
                    background: #c8e4ff;
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 17px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

                .title,
                .message {
                    display: block;
                }

                .title {
                    font-size: 1.5rem;
                }

                .message {
                    margin-top: 1rem;
                    font-size: 1.6rem;
                }
            }
        }

        /* Media query for small screens */
        @media only screen and (max-width: 600px) {
            .recent-chats {
                display: block; /* Show recent chats in a block on small screens */
                width: 100%;
            }

            .chatroom {
                display: none; /* Hide chatroom on small screens initially */
            }

            .hide-chat-list .recent-chats {
                display: none; /* Hide recent chats when viewing a chat on small screens */
            }

            .hide-chat-list .chatroom {
                display: block; /* Show chatroom when viewing a chat on small screens */
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .chat-box1 {
            width: 100%;
        }
    }

    /* Styles for back button */
    .back-button {
        margin-bottom: 10px;
        background-color: #333;
        color: #fff;
        padding: 8px;
        border: none;
        cursor: pointer;
    }

    .chatroom {
        width: 75%;
        height: 100%;
        @media screen and (max-width: 1000px) {
            height: 101%;
        }
        background: #ffffff;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;

        // display: flex;
        // flex-direction: column;

        .sender-area {
            background: #ffffff;
            padding: 20px;
            height: 20%;
            max-height: 100px;
            @media screen and (max-width: 1000px) {
                padding: 0px 10px;
            }
            // border-left: 1px solid #cbcbcb;
            // border-bottom: 1px solid #cbcbcb;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
            border-top-right-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .profile {
                font-size: 14px;
                line-height: 1.14285714;
                font-weight: 500;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;

                .roundedNameProfile {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 1rem;
                    @media screen and (max-width: 1200px) {
                        width: 40px;
                        height: 40px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 30px;
                        height: 30px;
                    }
                }
                .roundedNameProfileLetter {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 2px solid gray;
                    margin-right: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2rem;
                    font-weight: 600;
                    @media screen and (max-width: 1200px) {
                        width: 40px;
                        height: 40px;
                        font-size: 17px;
                        font-weight: 500;
                    }
                    @media screen and (max-width: 768px) {
                        width: 30px;
                        height: 30px;
                        font-size: 14px;
                    }
                }
                strong {
                    color: #333;
                    font-weight: 500;
                }
                div {
                    color: #008bdc;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.14285714;
                    font-weight: 500;
                    // cursor: pointer;
                }

                &.dropdown:hover {
                    background: #dddddd;
                }
            }
        }

        .message-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 85%;
            ::-webkit-scrollbar {
                width: 8px;
            }

            ::-webkit-scrollbar-thumb {
                background: #dddddd;
                border-radius: 16px;
            }

            .hire {
                background: #d9ead7;
                border-radius: 5px;
                color: #38c52b;
                padding-left: 17px;
                padding-right: 17px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .int {
                background: #ffdede;
                border-radius: 5px;
                color: #ca1c1c;
                padding-left: 17px;
                padding-right: 17px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .schdeule {
                background: #d2e1ee;
                border-radius: 5px;
                color: #0077e4;
                padding-left: 17px;
                padding-right: 17px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            .assign {
                background: #d2e1ee;
                border-radius: 5px;
                color: #0077e4;
                padding-left: 17px;
                padding-right: 17px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .conversations {
            padding: 2rem;
            height: 75%;
            overflow: auto;
            // border-bottom: 1px solid #f0eeee;
            // margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                padding: 2rem 1rem 2rem 1rem;
                height: 100%;
            }
            .date-time {
                padding: 1rem;
                text-align: center;
                font-size: 1.4rem;
            }
            .hoveredMessage {
                padding: 6px 10px;
                &:hover {
                    transition: 0.5s all cubic-bezier(0.215, 0.61, 0.355, 1);
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            }

            .conversation {
                font-weight: 400;
                color: #333;
                border: none;
                word-break: unset;
                border-radius: 16px;
                padding: 12px 16px 6px 12px;
                box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.12);
                word-wrap: break-word;
                text-rendering: optimizeLegibility;
                font-size: 14px;
                white-space: pre-wrap;
                width: fit-content;
                max-width: 70%;
                margin: 10px 0;
                display: flex;
                flex-direction: column;
            }
            .proposalMsgContainer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    width: 70%;
                    @media screen and (max-width: 1500px) {
                        width: 90%;
                    }
                }
            }
            .replyMsgBox {
                background-color: #dfdfdf;
                width: fit-content;
                max-width: 94%;
                padding: 1rem;
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                border-radius: 5px;
                .innerReplyMsgBox {
                    background-color: #f3f3f3;
                    border-radius: 5px;
                    padding: 0.5rem;
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 10px;
                }
                .replyIcon {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    transform: rotate(90deg);
                }
                .cross {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 1%;
                    top: 7%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .crossIcon {
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        transform: rotate(90deg);
                        cursor: pointer;
                    }
                }
                // display: none;
            }

            .conversation.left {
                justify-content: flex-start;
                background: #f8f8f8;
                margin-right: auto;
                align-items: flex-start;
            }

            .conversation.right {
                justify-content: flex-end;
                background: #eafcff;
                margin-left: auto;
                align-items: flex-end;
            }

            .conversation .time {
                font-weight: 500;
                text-align: right;
                font-size: 12px;
                line-height: 1.333333;
                margin-top: 8px;
                margin-bottom: 2px;
                color: #8a8a8a;
            }
        }

        .send-message-box {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 20%;
            // max-height: 80px;
            padding: 2rem 2rem;
            gap: 2rem;
            border-top: 1px solid #d6d6d6;
            border-bottom-right-radius: 6px;
            @media screen and (max-width: 1000px) {
                border-bottom: 1px solid #d6d6d6;
            }
            .btnsend {
                width: 80px;
                background: #bbb;
                border: 1px solid #bbb;
                border-radius: 3px;
                color: #fff;
                padding: 11px 15px;
                font-size: 14px;
                line-height: 16px;
            }
            .replyMsgBox {
                background-color: #f3f3f3;
                max-height: 200px;
                overflow-y: scroll;
                width: 100%;
                padding: 1rem;
                .innerReplyMsgBox {
                    background-color: #dbdbdb;
                    border-radius: 5px;
                    padding: 1rem;
                    position: relative;
                }
                .replyIcon {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    transform: rotate(90deg);
                }

                .cross {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 1%;
                    top: 7%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #bbbaba;
                    border-radius: 50%;
                    .crossIcon {
                        width: 15px;
                        height: 15px;
                        object-fit: cover;
                        transform: rotate(90deg);
                        cursor: pointer;
                    }
                }
                // display: none;
            }
            .btnsend:hover {
                background: #77c2ff;
            }

            input {
                width: 100%;
                padding: 1rem 1.3rem;
                border-radius: 3px;
                outline: none;
                border: 1px solid #d6d6d6;
            }

            div input:hover {
                border: 1px solid #77c2ff;
            }
        }
    }
}

/* ToggleSwitch.css */
.summary_toggle {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 16px;
    overflow: hidden; /* Hide the overflowing content */
    width: 215px;
}

.summary_toggle button {
    flex-grow: 1;
    cursor: pointer;
    padding: 5px 0;
    font-size: 14px;
    border: none;
    outline: none;
    background-color: transparent;
    transition: left 0.5s ease;
    position: relative;
}

.summary_toggle .switch.active {
    left: 100%;
    background-color: #2196f3;
    color: white;
    border-radius: 16px;
}

.summary_toggle .switch.active {
    left: 0;
}

.statusHire {
    margin-right: 16px;
    background-color: #e2ffcc;
    color: #00a896;
    cursor: pointer;
    padding: 7px 15px;
    font-weight: 600;
    min-width: 80px;
    line-height: 1.14285714;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.statusNotIntrested {
    margin: 0 16px;
    background-color: #fff2eb;
    color: #fc2d2d;
    cursor: pointer;
    padding: 7px 15px;
    font-weight: 600;
    min-width: 80px;
    line-height: 1.14285714;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.timestamp {
    color: rgb(182, 182, 182);
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.btnblue {
    width: 130px;
    color: rgb(56, 151, 230);
    background-color: #ceeaff;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    border-radius: 8px;
    margin-right: 10px;
}

.btnyellow {
    width: 130px;
    color: rgb(233, 199, 9);
    background-color: #fff5c9;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    border-radius: 8px;
}

.chatSliderDropdown1 {
    // margin-bottom: 250px;
}

.PopupBox {
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.sendAssignmentBtn {
    color: white;
    background-color: #0077e4;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    margin-top: 150px;
    margin-bottom: 20px;
}

.sendAssignmentBtn:hover {
    background-color: #166bbb;
}

@media screen and (max-width: 800px) {
    .btn3 {
        display: none;
    }

    .btn4 {
        display: none;
    }

    .date {
        display: none;
    }
}

.btn.chatSliderDropdown2.focus-visible,
.btn.chatSliderDropdown2:focus {
    outline: none;
    box-shadow: none; /* Optional: Remove box-shadow as well */
}

@media only screen and (max-width: 650px) {
    .statusHire {
        margin: 0;
        margin-right: 16px;
    }
    .statusNotIntrested {
        margin: 0;
        margin-right: 16px;
    }
}
